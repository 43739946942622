import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { FaSitemap, FaMapMarkerAlt } from 'react-icons/fa';

import { ACTIONS } from '../../reducers/group-reducer';
import { GroupContext } from '../../context/group-context';
import { AuthContext } from '../../../shared/context/auth-context';
import GroupIcon from '../../../assets/images/sitemap.svg';
import PlusIcon from '../../../assets/images/plus.svg';
import LoadingSpinner from '../UIElements/LoadingSpinner';

const Dropdown = (props) => {
	const { groups: groupContext, dispatch } = useContext(GroupContext);
	const auth = useContext(AuthContext);
	const { goTo, defaultValue, isLoading } = props;
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const toggle = () => setDropdownOpen((prevState) => !prevState);
	const [selected, setSelected] = useState(defaultValue);

	const groups =
		auth.userIsAdmin === true
			? groupContext?.adminAvailableGroups
			: groupContext?.userAvailableGroups;

	const onSelect = async (group) => {
		try {
			//TODO - Retrieve historical group from localstorage

			dispatch({
				type: ACTIONS.UPDATE_SELECTED_GROUP,
				payload: { selectedGroup: group },
			});

			auth.updateSelectedGroup(group.id);
			setSelected(group.groupName);

			goTo(group.id);
		} catch (e) {
			console.warn(
				'Unable to initialize onSelect. Please contact support. ',
				e
			);
		}
	};

	useEffect(() => {
		try {
			if (groups.length > 0) {
				const thisGroup = groups.find((group) => group.id === auth.groupId);
				dispatch({
					type: ACTIONS.UPDATE_SELECTED_GROUP,
					payload: { selectedGroup: thisGroup },
				});
				setSelected(thisGroup?.groupName);
				goTo(thisGroup.id);
				console.log('____NEW__SELECTED__GROUP___', thisGroup);
			}
		} catch (e) {
			console.log(e);
		}
	}, [selected, groups]);

	// useEffect(() => {
	//   if (groups.length > 0) {
	//     if (
	//       groups.filter((group) => {
	//         return group.groupName === selected;
	//       }).length === 0
	//     ) {
	//       onSelect(groups[0]);
	//     }
	//   }
	// }, [groups, selected, onSelect]);

	return (
		<div
			data-delay={0}
			className='button_default dropdown w-dropdown'
			onClick={toggle}
		>
			<div className='dropdown-toggle w-dropdown-toggle'>
				<div className='icon_header_button'>
					<FaMapMarkerAlt className='react-icons group-dropdown' size='1.2em' />
				</div>
				<div style={{ paddingTop: '2px' }}>
					{groups?.length > 0
						? String(selected)
						: "Oops... couldn't find groups."}{' '}
				</div>
				<div className='dropdown-toggle_icon w-icon-dropdown-toggle' />
			</div>
			<nav
				className={classnames('dropdown-list', 'w-dropdown-list', {
					'w--open': dropdownOpen,
				})}
			>
				{isLoading && <LoadingSpinner dark='dark' />}
				{!isLoading && groups.length > 0
					? groups.map((group, i) => (
							<button
								key={i}
								className='dropdown-list_toggle w-dropdown-link'
								onClick={() => onSelect(group)}
							>
								{group.groupName}
							</button>
					  ))
					: groups.map((group, i) => (
							<button
								key={i}
								className='dropdown-list_toggle w-dropdown-link'
								onClick={() => onSelect(group)}
							>
								{group.groupName}
							</button>
					  ))}
				<div className='edit_groups'>
					<div className='subtle_dividing_line no_margin' />
					{/* TODO remove hard-coded links */}
					<Link
						to={`/group/${auth.accountId}/create`}
						className='dropdown-list_toggle w-inline-block'
					>
						<div className='dropdown-link'>New Location</div>
						<img src={PlusIcon} alt='Add Group' className='dropdown_icon' />
					</Link>
					{/* TODO remove hard-coded links */}
					<Link
						to={
							auth.isAdmin === true
								? `/${auth.accountId}/groups`
								: `/${auth.userId}/groups`
						}
						className='dropdown-list_toggle w-inline-block'
					>
						<div className='dropdown-link'>Manage Locations</div>
						<img
							src={GroupIcon}
							alt='Manage Groups'
							className='dropdown_icon'
						/>
					</Link>
				</div>
			</nav>
		</div>
	);
};
export default Dropdown;
