import React, { useState } from "react";
import classnames from "classnames";
// import { FaRegCalendarAlt } from "react-icons/fa";

const DateDropDown = ({ setDateRange, dateRange }) => {
  Date.prototype.toISODateString = function () {
    return this.toISOString().substr(0, 10);
  };

  Date.prototype.toDateFromDays = function (n) {
    n = parseInt(n) || 0;
    var newDate = new Date(this.getTime());
    newDate.setDate(this.getDate() + n);
    return newDate;
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const dates = [
    {
      label: "All Time",
      value: 0,
    },
    {
      label: "Last Week",
      value: 7,
    },
    {
      label: "Last Month",
      value: 30,
    },
    {
      label: "Last Quarter",
      value: 90,
    },
    {
      label: "Last 12 Months",
      value: 365,
    },
  ];
  const [selected, setSelected] = useState(
    dateRange !== ""
      ? dates.find((date) => date.value === dateRange).label
      : dates[0].label
  );

  const onSelect = (label, value) => {
    setSelected(label);
    setDateRange(value);
  };

  return (
    <div
      data-delay={0}
      className="button_default dropdown time-filter w-dropdown"
      onClick={toggle}
    >
      <div className="dropdown-toggle w-dropdown-toggle">
        {/* <div className="icon_header_button">
          <FaRegCalendarAlt
            className="react-icons group-dropdown"
            size="1.2em"
          />
        </div> */}
        <div>{selected}</div>
        <div className="dropdown-toggle_icon w-icon-dropdown-toggle" />
      </div>
      <nav
        className={classnames("dropdown-list", "w-dropdown-list", {
          "w--open": dropdownOpen,
        })}
      >
        {dates.map((date, i) => (
          <button
            key={i}
            className="dropdown-list_toggle w-dropdown-link"
            onClick={() => onSelect(date.label, date.value)}
          >
            {date.label}
          </button>
        ))}
      </nav>
    </div>
  );
};
export default DateDropDown;
