import React, { useState } from "react";
import { withRouter, useParams } from "react-router-dom";

import { useForm } from "../../shared/hooks/form-hook";
import Button from "../../shared/components/FormElements/Button";
import Input from "../../shared/components/FormElements/Input";
import {
  VALIDATOR_MINLENGTH,
  VALIDATOR_REQUIRE,
} from "../../shared/util/validators";
import Logo from "../../assets/images/brisk_logo.svg";
import MainFooter from "../../shared/components/Navigation/MainFooter";
import Modal from "../../shared/components/UIElements/Modal";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import { useHttpClient } from "../../shared/hooks/http-hook";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";

const AddNewMember = () => {
  const userInviteToken = useParams().userInviteToken;
  const invitedEmail = useParams().invitedEmail;
  //   console.log("User Invite Token ", userInviteToken);
  const { isLoading, isError, sendRequest, clearError } = useHttpClient();
  const [showSuccess, setShowSuccess] = useState(false);

  const [formState, inputHandler] = useForm(
    {
      userFirstName: {
        value: "",
        isValid: false,
      },
      userLastName: {
        value: "",
        isValid: false,
      },
      userEmail: {
        value: invitedEmail,
        isValid: true,
      },
      userPassword: {
        value: "",
        isValid: false,
      },
    },
    false
  );

  const showSuccessModal = () => {
    setShowSuccess(true);
  };

  const hideSuccessModal = () => {
    setShowSuccess(false);
  };

  const successGoBackHandler = () => {
    setShowSuccess(false);
    window.location.replace("/members");
  };

  const userChangePasswordHandler = async (event) => {
    event.preventDefault();

    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/new-member/${userInviteToken}/`,
        "POST",
        JSON.stringify({
          userFirstName: formState.inputs.userFirstName.value,
          userLastName: formState.inputs.userFirstName.value,
          userEmail: invitedEmail,
          userPassword: formState.inputs.userPassword.value,
          // groupId: currentGroup._id
        }),
        { "Content-Type": "application/json" }
      );

      showSuccessModal();
    } catch (err) {
      //TODO Handle success modal catch error and verification message checking.
    }
  };

  return (
    <React.Fragment>
      <ErrorModal error={isError} onClear={clearError} />
      <Modal
        show={showSuccess}
        onCancel={hideSuccessModal}
        header="Welcome!"
        description={`Your profile has been created. Welcome to getseen.`}
        footer={
          <React.Fragment>
            <Button
              onClick={successGoBackHandler}
              buttonMargin="btn--14px--right"
            >
              Okay
            </Button>
          </React.Fragment>
        }
      ></Modal>

      <div className="auth_wrapper">
        <div>
          <div className="nav_container" />
          <Button
            //TODO complete button Login link
            to="/members"
            buttonStyle="btn--default--dark"
            buttonCustom="btn--auth--float"
          >
            Log In
          </Button>
          <div className="auth_element_container">
            <img src={Logo} alt="brisk-logo" className="brisk_auth_logo" />
            <h4 className="no_bold">One last thing...</h4>
            <h4 className="_20px_margin_bottom">
              <strong>Please complete your profile.</strong>
            </h4>
            <div className="w-form">
              <form
                id="changePassword"
                onSubmit={userChangePasswordHandler}
                className="w-clearfix"
              >
                <Input
                  id="userFirstName"
                  element="input"
                  type="text"
                  placeholder="Your first name"
                  validators={[VALIDATOR_REQUIRE()]}
                  errorText="You must include your first name."
                  onInput={inputHandler}
                />
                <Input
                  id="userLastName"
                  element="input"
                  type="text"
                  placeholder="Your last name"
                  validators={[VALIDATOR_REQUIRE()]}
                  errorText="You must include your last name."
                  onInput={inputHandler}
                />

                <Input
                  id="userEmail"
                  element="input"
                  type="email"
                  placeholder="Type your email"
                  validators={[VALIDATOR_MINLENGTH(6)]}
                  errorText="Passwords must be at least 6 characters long."
                  initialValue={invitedEmail}
                  initialValid={true}
                  inputClass="disabled"
                  disabled={true}
                  onInput={inputHandler}
                />
                <Input
                  id="userPassword"
                  element="input"
                  type="password"
                  placeholder="Create a password"
                  validators={[VALIDATOR_MINLENGTH(6)]}
                  errorText="Passwords must be at least 6 characters long."
                  onInput={inputHandler}
                />

                <Button
                  type="submit"
                  data-wait="Sipping tea..."
                  buttonStyle="btn--default--dark"
                  buttonCustom="btn--auth"
                  disabled={!formState.isValid}
                >
                  {isLoading ? <LoadingSpinner /> : "Continue"}
                </Button>
              </form>
            </div>
          </div>
          <MainFooter />
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(AddNewMember);
