import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import { FaStarHalfAlt, FaCheck, FaCommentDots } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { GroupContext } from '../../context/group-context';

import './GoalModal.css';

import Backdrop from './Backdrop';

const ModalContent = (props) => {
  const {
    groups: { selectedGroup },
  } = useContext(GroupContext);

  const content = (
    <div className='notice_container'>
      <button
        className='close_icon absolute_notice w-inline-block'
        onClick={props.onCancel}
      />
      <header className={`notice_heading ${props.headerClass}`}>
        <h3>
          <strong>{props.header}</strong>
        </h3>
      </header>

      <div className='review_services_container'>
        {/* {selectedGroup.groupConnections.googleMyBusiness.isConnected} */}

        <Link
          to={`/messages/${selectedGroup.id}/custom/create`}
          className='service_link w-inline-block'
          onClick={props.onClick}
        >
          <div className='goal_icon'>
            <FaCommentDots />
          </div>
          <div className='review_text'>
            Send a <strong>Followup</strong>&nbsp;message
            <strong />
          </div>
        </Link>

        {/* <Link */}
        {/*   to={ */}
        {/*     selectedGroup.groupConnections.googleMyBusiness.isConnected === true */}
        {/*       ? `/messages/${selectedGroup.id}/reviews/create` */}
        {/*       : `#` */}
        {/*   } */}
        {/*   // to={`/messages/${selectedGroup.id}/reviews/create`} */}
        {/*   className={ */}
        {/*     selectedGroup.groupConnections.googleMyBusiness.isConnected === true */}
        {/*       ? 'service_link w-inline-block' */}
        {/*       : 'service_link disabled w-inline-block' */}
        {/*   } */}
        {/*   onClick={ */}
        {/*     selectedGroup.groupConnections.googleMyBusiness.isConnected === true */}
        {/*       ? props.onClick */}
        {/*       : null */}
        {/*   } */}
        {/* > */}
        {/*   <div className='goal_icon'> */}
        {/*     <FaStarHalfAlt /> */}
        {/*   </div> */}
        {/*   <div className='review_text'> */}
        {/*     Send a <strong>Sales</strong> message */}
        {/*     <strong /> */}
        {/*   </div> */}
        {/*   <div className='coming-soon'> */}
        {/*     <div className='coming-soon-text'>Coming Soon</div> */}
        {/*   </div> */}
        {/* </Link> */}

        {/* <Link
					to={`/messages/${selectedGroup.id}/feedback/create`}
					className='service_link w-inline-block'
					onClick={props.onClick}
				>
					<div className='goal_icon'>
						<FaCheck />
					</div>
					<div className='review_text'>
						Send a <strong>Feedback</strong> request
						<strong />
					</div>
				</Link> */}

      </div>

      {/* <p
        className={`default_description_text align-left _30px_padding_bottom ${props.descriptionClass}`}
      >
        {props.description}
      </p>
      <form
        onSubmit={
          props.onSubmit ? props.onSubmit : (event) => event.preventDefault()
        }
      >
        <div className="modal_content">{props.children}</div>

        <footer
          className={`action_buttons_container align_right ${props.footerClass}`}
        >
          {props.footer}
        </footer>
      </form> */}
    </div>
  );
  return ReactDOM.createPortal(content, document.getElementById('modal-hook'));
};

const Modal = (props) => {
  return (
    <React.Fragment>
      {props.show && <Backdrop onClick={props.onCancel} />}
      <CSSTransition
        in={props.show}
        mountOnEnter
        unmountOnExit
        timeout={0}
        classNames='modal'
      >
        <ModalContent {...props} />
      </CSSTransition>
    </React.Fragment>
  );
};

export default Modal;
