import React from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";

import Button from "../../shared/components/FormElements/Button";
import Backdrop from "../../shared/components/UIElements/Backdrop";
import { FaUserAlt, FaCommentAlt, FaClock, FaHashtag } from "react-icons/fa";

import "./HistoryPreview.css";

const HistoryContent = (props) => {
  const content = (
    <div className="notice_container expanded">
      <h3 className="notice_heading">
        <strong>{props.header}</strong>
      </h3>
      <button
        type="button"
        className="close_icon absolute_notice w-inline-block"
      />
      <div className="message-details-container">
        <div className="statistics-box no-padding-bottom">
          <h4 className="detail-title">
            <FaUserAlt className="react-icons margin-right" /> Customer
          </h4>
          <div className="message-box">
            <div className="row-content">
              <div>
                <strong>Name: </strong>
                {props.name}
              </div>
              <div>
                <strong>Channel:&nbsp;</strong>
                {props.channel}
              </div>
              <div>
                <strong>Contact: </strong>
                {props.contact}
              </div>
              {/* <div>
                <strong>New Customer: </strong>
                {props.newCustomer}
              </div> */}
            </div>
          </div>
        </div>

        <div className="statistics-box no-padding-bottom">
          <h4 className="detail-title">
            <FaCommentAlt className="react-icons margin-right" /> Message
          </h4>
          <div className="message-box-container">
            <div className="message-box">
              <div>{props.message}</div>
            </div>
          </div>
        </div>
        <div className="activity-container">
          <div className="statistics-box no-padding-bottom">
            <h4 className="detail-title">
              <FaClock className="react-icons margin-right" /> Activity
            </h4>
            <div className="message-box-container w-clearfix">
              <div className="message-box margin-bottom">
                <div className="row-content">
                  <div>
                    <strong>Sent </strong>
                    {props.timeAgo}
                  </div>
                </div>
              </div>
              <div className="message-box margin-bottom">
                <div className="row-content">
                  <div>
                    <strong>Opened ⏱ </strong> coming soon...
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="statistics-box no-padding-bottom message">
            <h4 className="detail-title">
              <FaHashtag className="react-icons margin-right hashtag" /> Type
            </h4>
            <div className="message-box-container">
              <div className="message-box">
                <div>
                  #<strong>{props.goal} </strong> Message
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="action_buttons_container align_right">
        {/* <Button
          buttonMargin="btn--14px--right"
          type="button"
          onClick={props.onCancel}
        >
          Create Template
        </Button>
        <Button
          buttonMargin="btn--14px--right"
          type="button"
          onClick={props.onCancel}
        >
          Send Again
        </Button> */}
        <Button
          buttonStyle="btn--default--dark"
          type="button"
          onClick={props.onCancel}
        >
          Done
        </Button>
      </div>
    </div>
  );
  return ReactDOM.createPortal(content, document.getElementById("modal-hook"));
};

function HistoryPreview(props) {
  return (
    <React.Fragment>
      {props.show && <Backdrop onClick={props.onCancel} />}
      <CSSTransition
        in={props.show}
        mountOnEnter
        unmountOnExit
        timeout={0}
        classNames="modal"
      >
        <HistoryContent {...props} />
      </CSSTransition>
    </React.Fragment>
  );
}

export default HistoryPreview;
