import React, { useState, useEffect, useContext } from "react";
import { FaCommentDots, FaSearch } from "react-icons/fa";

import HistoryList from "../components/HistoryList";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import NoticeHistory from "../components/NoticeHistory";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { GroupContext } from "../../shared/context/group-context";
import Button from "../../shared/components/FormElements/Button";
import TableNavigation from "../../shared/components/Navigation/TableNavigation";
import NoticeNoHistory from "../components/NoticeNoHistory";

const History = () => {
  const {
    groups: { selectedGroup },
  } = useContext(GroupContext);
  const [loadedHistory, setLoadedHistory] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [historyPerPage] = useState(40);
  const [documents, setDocuments] = useState(0);
  const { isLoading, isError, sendRequest, clearError } = useHttpClient();

  //Search component
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState(loadedHistory);

  const handleSearchOnChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
    setSearchResults(
      loadedHistory.filter(
        (history) =>
          history.messageTo.messageName
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          history.messageTo.messagePhone
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
      )
    );
  };

  // const fetchFeedbackPoling = async () => {
  //   try {
  //     const responseData = await sendRequest(
  //       `${process.env.REACT_APP_BACKEND_URL}/feedback/group/${groupId}?page=${currentPage}&limit=${feedbackPerPage}`
  //     );
  //     setLoadedFeedback((prev) => [...prev, ...responseData.results]);
  //   } catch (err) {}
  // };

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/messages/group/${selectedGroup.id}?page=${currentPage}&limit=${historyPerPage}`
        );

        setLoadedHistory(responseData?.results ? responseData?.results : []);
        setDocuments(responseData.documents ? responseData.documents : 0);
      } catch (err) {
        console.log(err);
      }
    };
    fetchHistory();
  }, [sendRequest, selectedGroup.id]);

  // useEffect(() => {
  //   const searchHistory = async () => {
  //     try {
  //       const responseData = await sendRequest(
  //         `${process.env.REACT_APP_BACKEND_URL}/messages/group/${selectedGroup.id}`
  //       );

  //       setLoadedHistory(responseData ? responseData : []);
  //       // setDocuments(responseData.length || 0);
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };

  //   if (loadedHistory.length > 0) {
  //     const filteredHistory = loadedHistory.filter((message) => {
  //       return (
  //         message.messageTo.messageName.toLowerCase().includes(searchTerm) ||
  //         message.messageTo.messageName.includes(searchTerm) ||
  //         message.messageTo.messagePhone.includes(searchTerm)
  //       );
  //     });
  //     setSearchResults(filteredHistory);
  //     setDocuments(filteredHistory.length);
  //   }

  //   // const filteredHistory =
  //   //   loadedHistory.length > 0 &&
  //   //   loadedHistory.filter((message) => {
  //   //     return (
  //   //       message.messageTo.messageName.toLowerCase().includes(searchTerm) ||
  //   //       message.messageTo.messageName.includes(searchTerm) ||
  //   //       message.messageTo.messagePhone.includes(searchTerm)
  //   //     );
  //   //   });

  //   searchHistory();
  // }, [searchTerm]);

  //   const results = arrayOfObject.filter((obj)=>{
  //     return Object.keys(obj).reduce((acc, curr)=>{
  //           return acc || obj[curr].toLowerCase().includes(term);
  //     }, false);
  // });

  const handleNextPage = () => {
    setCurrentPage((prev) => prev + 1);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handlePreviousPage = () => {
    setCurrentPage((prev) => prev - 1);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const calcLastPage = Math.ceil(documents / historyPerPage);

  return (
    <div>
      <ErrorModal error={isError} onClear={clearError} />
      {loadedHistory.length > 0 && <NoticeHistory />}

      {documents === 0 && <NoticeNoHistory />}

      <>
        <div className="filter_bubbles_wrapper">
          <div className="left_side">
            <Button
              buttonSize="btn--default"
              buttonMargin="btn--14px--right"
              buttonCustom="btn--container--style"
            >
              <div className="icon_header_button">
                <FaCommentDots size="1.28em" className="react-icons" />
              </div>

              {documents > 0 &&
                `${
                  searchTerm ? searchResults.length : loadedHistory.length
                } of ${documents} Message${documents > 1 ? "s" : ""}`}
              {documents === 0 && `${documents} Messages`}
              {/* 
              {searchTerm
                ? `${String(documents)} Message${documents > 1 ? "s" : ""}`
                : `${String(historyPerPage)} of ${String(documents)} Message${
                    documents > 1 ? "s" : ""
                  }`} */}
            </Button>
          </div>
          <div className="container_nav_top">
            <div className="search_input_cont">
              <input
                id="messageCount"
                type="text"
                className="search_input"
                placeholder="Search name or phone..."
                onChange={handleSearchOnChange}
              />
              <div className="search_icon_cont">
                <FaSearch />
              </div>
            </div>
          </div>
        </div>
        {isLoading && <LoadingSpinner dark="dark" />}
        {!isLoading && loadedHistory.length > 0 && (
          <div className="tile_wrapper">
            <div className="table_tile">
              <div className="w-layout-grid grid-titles">
                <div>Name</div>
                <div>Mobile</div>
                <div>Body</div>
                <div>Goal</div>
                <div>Status</div>
              </div>
              <div className="w-layout-grid grid-people-list">
                <HistoryList
                  items={searchTerm ? searchResults : loadedHistory}
                />
              </div>
            </div>
          </div>
        )}

        {!searchTerm && !isLoading && documents > 0 && (
          <TableNavigation
            onClickNext={handleNextPage}
            onClickPrevious={handlePreviousPage}
            firstPageNumber={String(currentPage)}
            lastPageNumber={String(calcLastPage)}
            disablePrev={currentPage === 1 ? true : false}
            disableNext={currentPage === calcLastPage ? true : false}
          />
        )}
      </>
    </div>
  );
};

export default History;
