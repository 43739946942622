import React from 'react';

import Button from '../../shared/components/FormElements/Button';

function MessageTemplateButton(props) {
	return (
		<Button
			key={props.id}
			id={props.id}
			onClick={props.onClick}
			type='button'
			buttonMargin='btn--14px--right'
			buttonCustom='btn--template--button'
		>
			{props.children}
		</Button>
	);
}

export default MessageTemplateButton;
