import { useReducer } from "react";

export const ACTIONS = {
  USER_AVAILABLE_GROUPS: "user_available_groups",
  ADMIN_AVAILABLE_GROUPS: "admin_available_groups",
  UPDATE_SELECTED_GROUP: "update_selected_group",
  CLEAR_GROUPS: "clear_groups",
  CONNECTIONS: {
    UPDATE_GOOGLE_MY_BUSINESS: "update_google_my_business",
    SAVE_GOOGLE_MY_BUSINESS_LOCATION: "save_google_my_business_location",
    DISCONNECT_GOOGLE_MY_BUSINESS_ACCOUNT:
      "disconnect_google_my_business_account",
    UPDATE_REPORT_LINK: "update_report_link",
    UPDATE_GROUP_LINKS: "update_group_links",
  },
};

// TODO - Update default questions on typeform integration
// Question 1 - Would you recommend us to your family and friends?
// Question 2 - How well did we keep in touch?

export const initState = {
  userAvailableGroups: [],
  adminAvailableGroups: [],
  selectedGroup: {
    groupAccount: "",
    groupConnections: {},
    groupCreated: "",
    groupCreator: "",
    groupDescription: "",
    groupMessages: [],
    groupName: "",
    groupReviews: [],
    groupUsers: [],
    id: "",
  },
};

const groupReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.UPDATE_SELECTED_GROUP:
      const { selectedGroup } = action.payload;
      return {
        ...state,
        selectedGroup: {
          groupAccount: selectedGroup.groupAccount,
          groupConnections: selectedGroup.groupConnections,
          groupCreated: selectedGroup.groupCreated,
          groupCreator: selectedGroup.groupCreator,
          groupDescription: selectedGroup.groupDescription,
          groupMessages: selectedGroup.groupMessages,
          groupName: selectedGroup.groupName,
          groupReviews: selectedGroup.groupReviews,
          groupUsers: selectedGroup.groupUsers,
          id: selectedGroup.id,
        },
      };

    case ACTIONS.USER_AVAILABLE_GROUPS:
      const { userAvailableGroups } = action.payload;
      return {
        ...state,
        userAvailableGroups: userAvailableGroups,
      };

    case ACTIONS.ADMIN_AVAILABLE_GROUPS:
      const { adminAvailableGroups } = action.payload;
      return {
        ...state,
        adminAvailableGroups: adminAvailableGroups,
      };

    case ACTIONS.CONNECTIONS.UPDATE_GOOGLE_MY_BUSINESS:
      const { googleMyBusiness } = action.payload;
      return {
        ...state,
        googleMyBusiness: {
          isConnected: googleMyBusiness.isConnected,
          access_token: googleMyBusiness.access_token,
          refresh_token: googleMyBusiness.refresh_token,
          tokenCreated: googleMyBusiness.tokenCreated,
          account: {
            accountId: googleMyBusiness.account.accountId,
            name: googleMyBusiness.account.name,
          },
          location: {
            locationId: googleMyBusiness.location.locationId,
            name: googleMyBusiness.location.name,
            reviewShareUrl: googleMyBusiness.location.reviewShareUrl,
          },
        },
      };

    case ACTIONS.CONNECTIONS.UPDATE_REPORT_LINK:
      const { reporting } = action.payload;
      return {
        ...state,
        reporting: {
          isConnected: reporting.isConnected,
          reportLink: reporting.reportLink,
        },
      };

    case ACTIONS.CONNECTIONS.UPDATE_GROUP_LINKS:
      const { groupLinks } = action.payload;
      return {
        ...state,
        groupLinks: {
          reviewLink: groupLinks.reviewLink,
          feedbackLink: groupLinks.feedbackLink,
          reportLink: groupLinks.reportLink,
          googleReviewLink: groupLinks.googleReviewLink,
          facebookReviewLink: groupLinks.facebookReviewLink,
        },
      };

    case ACTIONS.CLEAR_GROUPS:
      const { initialState } = action.payload;
      return initialState;

    default:
      return state;
  }
};

export const useGroupReducer = () => {
  const [groups, dispatch] = useReducer(groupReducer, initState);
  return [groups, dispatch];
};
