import React from "react";

import HistoryItem from "./HistoryItem";

const HistoryList = (props) => {
  return (
    <>
      {props.items.map((history) => {
        return (
          <HistoryItem
            key={history._id}
            id={history._id}
            name={history.messageTo.messageName}
            contact={history.messageTo.messagePhone}
            created={history.messageCreated}
            goal={history.messageGoal}
            body={history.messageBody}
          />
        );
      })}
    </>
  );
};

export default HistoryList;
