import React, { useState, useContext } from 'react';
import { FaEye, FaExternalLinkAlt } from 'react-icons/fa';

import ModalView from '../../shared/components/Navigation/ModalView';
import Button from '../../shared/components/FormElements/Button';
import FormButtonWrapper from '../../shared/components/FormElements/FormButtonWrapper';
import Input from '../../shared/components/FormElements/Input';
import { VALIDATOR_MINLENGTH } from '../../shared/util/validators';
import { useForm } from '../../shared/hooks/form-hook';
import ModalHeader from '../../shared/components/Navigation/ModalHeader';
import Modal from '../../shared/components/UIElements/Modal';
import ErrorModal from '../../shared/components/UIElements/ErrorModal';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';
import { GroupContext } from '../../shared/context/group-context';
import MessagePreview from '../components/MessagePreview';
import { findFirstName } from '../../shared/util/findFirstName';
import { validateNumber } from '../../shared/util/validateNumber';

import './Messages.css';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import MessageTemplateList from '../components/MessageTemplateList';

const MessageReview = () => {
	const {
		groups: { selectedGroup },
	} = useContext(GroupContext);

	const { isLoading, isError, sendRequest, clearError } = useHttpClient();
	const auth = useContext(AuthContext);
	// const [loadedGroup, setLoadedGroup] = useState();
	const [showReturnWarning, setShowReturnWarning] = useState(false);
	const [showSuccess, setShowSuccess] = useState(false);
	const [previewModal, setPreviewModal] = useState(false);

	function setNativeValue(element, value) {
		const valueSetter = Object.getOwnPropertyDescriptor(element, 'value').set;
		const prototype = Object.getPrototypeOf(element);
		const prototypeValueSetter = Object.getOwnPropertyDescriptor(
			prototype,
			'value'
		).set;

		if (valueSetter && valueSetter !== prototypeValueSetter) {
			prototypeValueSetter.call(element, value);
		} else {
			valueSetter.call(element, value);
		}
	}

	const [formState, inputHandler, setFormData] = useForm(
		{
			messageTo: {
				value: '',
				isValid: false,
			},
			messageBody: {
				value: '',
				isValid: false,
			},
		},
		false
	);

	const showPreviewModal = () => {
		setPreviewModal(true);
	};

	const hidePreviewModal = () => {
		setPreviewModal(false);
	};

	const showConfirmReturnWarning = () => {
		setShowReturnWarning(true);
	};

	const showSuccessModal = () => {
		setShowSuccess(true);
	};

	const hideReturnWarning = () => {
		setShowReturnWarning(false);
	};

	const hideSuccessModal = () => {
		setShowSuccess(false);
	};

	const confirmReturnWarning = () => {
		setShowReturnWarning(false);
		console.log('EXITING....');
		window.history.back();
	};

	const successGoBackHandler = () => {
		setShowSuccess(false);
		console.log('GOING BACK....');
		window.history.back();
	};

	// if (isLoading) {
	//   return (
	//     <div className="center">
	//       <LoadingSpinner dark="dark" />
	//     </div>
	//   );
	// }

	const templateClickHandler = (storedContent) => {
		const textarea = document.querySelector('#messageBody');
		setNativeValue(textarea, storedContent);
		textarea.dispatchEvent(new Event('input', { bubbles: true }));
		setFormData(
			{
				messageTo: {
					value: formState.inputs.messageTo.value,
					isValid: true,
				},
				messageBody: {
					value: storedContent,
					isValid: true,
				},
			},
			true
		);
	};

	const messageSubmitHandler = async (event) => {
		event.preventDefault();

		const messageTo = formState.inputs.messageTo.value
			.trim()
			.split('\n')
			.map((input) => validateNumber(input))
			.join('\n');

		const processedMessage = messageTo.split('\n').map((name) => ({
			messageTo: name,
			messageBody: formState.inputs.messageBody?.value
				?.replace(
					'--CustomerFirstName--',
					findFirstName(name.split(',')[0]) || ''
				)
				?.replace('--GroupName--', selectedGroup.groupName)
				// .replace(
				// 	'--ReviewLink--',
				// 	selectedGroup.groupConnections.googleMyBusiness.location
				// 		.reviewShareUrl
				// )
				?.replace('--MyFirstName--', auth.userFirstName || 'Chris'),
		}));

		try {
			await sendRequest(
				`${process.env.REACT_APP_BACKEND_URL}/messages/create`,
				'POST',
				JSON.stringify({
					messageGoal: 'Review',
					message: processedMessage,
					messageGroup: selectedGroup.id,
					messageCreator: auth.userId,
					// messageAccount: auth.accountId,
				}),
				{ 'Content-Type': 'application/json' }
			);

			showSuccessModal();
		} catch (err) {
			console.log('Error: ', err);
		}
	};

	return (
		<ModalView>
			<ErrorModal error={isError} onClear={clearError} />

			<MessagePreview
				show={previewModal}
				messageBody={formState.inputs.messageBody.value}
				messageTo={formState.inputs.messageTo.value}
				onCancel={hidePreviewModal}
				header='Preview your message...'
			/>
			<ModalHeader
				pageTitle='Create Review Message'
				pageSubTitle={`as ${selectedGroup.groupName}.`}
			>
				{/* <Button
        // onClick={sendTestMessage}
        >
          Need Help?
        </Button> */}

				{selectedGroup.groupConnections.googleMyBusiness.location.reviewShareUrl
					.length > 0 && (
					<Button
						href={
							// selectedGroup.groupConnections.googleMyBusiness.location
							// 	.reviewShareUrl
							`https://reviews.fastfeedback.app?name=${auth.userFirstName}&messageid=1234&googlelink=${selectedGroup.groupConnections.googleMyBusiness.location.reviewShareUrl}&companyname=${selectedGroup.groupName}`
						}
						target='_blank'
						// onClick={sendTestMessage}
						buttonMargin='btn--14px--right'
					>
						<div className='icon_header_button'>
							{' '}
							<FaExternalLinkAlt className='react-icons' />{' '}
						</div>{' '}
						Preview Review Link
					</Button>
				)}

				<Button onClick={showPreviewModal}>
					<div className='icon_header_button'>
						{' '}
						<FaEye size='1.28em' className='react-icons' />{' '}
					</div>{' '}
					Preview Message
				</Button>
			</ModalHeader>
			<Modal
				show={showReturnWarning}
				onCancel={hideReturnWarning}
				header='You sure?'
				description={`You're about to go back in the middle of creating a Review message. Your message will not be saved. Are you sure you want to continue?`}
				footer={
					<React.Fragment>
						<Button onClick={hideReturnWarning} buttonMargin='btn--14px--right'>
							Cancel
						</Button>
						<Button
							onClick={confirmReturnWarning}
							buttonStyle='btn--default--dark'
							buttonCustom='btn--wide'
						>
							Confirm
						</Button>
					</React.Fragment>
				}
			></Modal>

			<Modal
				show={showSuccess}
				onCancel={hideSuccessModal}
				header='Sent!'
				description={`Review message(s) sent successfully from ${selectedGroup.groupName}. Send another?`}
				footer={
					<React.Fragment>
						<Button
							onClick={successGoBackHandler}
							buttonMargin='btn--14px--right'
						>
							Go Back
						</Button>
						<Button
							onClick={hideSuccessModal}
							buttonStyle='btn--default--dark'
							buttonCustom='btn--wide'
						>
							Send Another
						</Button>
					</React.Fragment>
				}
			></Modal>
			<form onSubmit={messageSubmitHandler}>
				<Input
					id='messageTo'
					element='textarea'
					type='text'
					autoFocus='true'
					label='Add Customers'
					description='Paste one or more customers below (one per line, customer first name is required, last name is optional).'
					placeholder='Joe Bloggs, +447888077505'
					validators={[VALIDATOR_MINLENGTH(1)]}
					errorText='We need a valid customer name and number or email to send a message'
					onInput={inputHandler}
				/>

				<React.Fragment>
					<div className='settings_toggles'>
						<label className={`settings_label`}>Templates</label>
						<p
							className={`default_description_text align-left _10px_margin_bottom`}
						>
							Click on a template below above to save time ⏰.
						</p>
					</div>
					<div className='templates_container'>
						<MessageTemplateList
							tag='Review'
							templateClick={templateClickHandler}
						/>
					</div>
					<Input
						id='messageBody'
						element='textarea'
						type='text'
						label='Review Message'
						description='Create your own, or click on a template above.'
						placeholder='Type something...'
						validators={[VALIDATOR_MINLENGTH(6)]}
						errorText='Review message must contain atleast 6 characters.'
						//   initialValue={loadedGroup.groupFeedbackQuestion}
						//   initialValid={true}
						onInput={inputHandler}
					/>
				</React.Fragment>

				<FormButtonWrapper>
					<Button
						buttonMargin='btn--14px--right'
						onClick={showConfirmReturnWarning}
					>
						Cancel
					</Button>
					<Button
						type='submit'
						buttonStyle='btn--default--dark'
						buttonSize='btn--small'
						buttonCustom='btn--wide'
						// disabled={!formState.isValid}
					>
						{isLoading ? <LoadingSpinner /> : 'Send Message'}
					</Button>
				</FormButtonWrapper>
			</form>
		</ModalView>
	);
};

export default MessageReview;
