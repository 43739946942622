import React from "react";
// import { FaStarHalfAlt } from "react-icons/fa";

import Button from "../../shared/components/FormElements/Button";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";

const NoticeImportingReviews = () => {
  return (
    <div className="container_state_notice">
      <div className="notification_tile feedback">
        <div className={`notification_img_cont`}>
          <LoadingSpinner dark="dark" />
        </div>
        <div className="notification_text_wrapper">
          <h3 className="notification_header_black">
            Checking for new reviews...
          </h3>
          <div className="notification_sub_text max_width">
            This can sometimes take a few seconds...
            {/* <a href="/guide-link" className="embedded_text_link">
              <span>Learn More</span>
            </a> */}
          </div>
        </div>
        <Button to="" buttonCustom="btn--notice">
          Okay
        </Button>
      </div>
    </div>
  );
};

export default NoticeImportingReviews;
