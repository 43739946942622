import React, { createContext } from "react";
import { useGroupReducer } from "../../shared/reducers/group-reducer";

export const GroupContext = createContext(null);

export const CreateGroupContext = (props) => {
  const [groups, dispatch] = useGroupReducer();

  return (
    <GroupContext.Provider value={{ groups, dispatch }}>
      {props.children}
    </GroupContext.Provider>
  );
};
