import { createContext } from "react";

export const AuthContext = createContext({
  isLoggedIn: false,
  token: null,
  userId: null,
  accountId: null,
  isAdmin: false,
  groups: [],
  groupId: null,
  userFirstName: null,
  userPreferences: null,
  login: () => { },
  logout: () => { },
  updateSelectedGroup: () => { },
  updateUserPreferences: () => { },
});
