export const findFirstName = (fullName) => {
	const prefix = [
		'Mrs',
		'Mr',
		'Dr',
		'Miss',
		'Ms',
		'Rev',
		'Lord',
		'Lady',
		'Sir',
	];
	const pref = prefix.filter((pref) =>
		fullName.toLowerCase().includes(pref.toLowerCase())
	);
	if (pref.length > 0) {
		return `${pref[0]}. ${fullName.split(' ')[1]}`;
	} else {
		return fullName.split(' ').shift();
	}
};
