import React, { useState } from "react";
import { FaGift, FaPen } from "react-icons/fa";

import SquareButton from "../FormElements/SquareButton";
import ProfileButton from "../FormElements/ProfileButton";
import GoalModal from "../UIElements/GoalModal";

const NavButtonsTop = (props) => {
  const [showGoalModal, setShowGoalModal] = useState(false);

  const hideGoalModal = () => {
    setShowGoalModal(false);
  };

  const clickedHandler = () => {
    setShowGoalModal(true);
  };

  const hideModalOnLinkClick = () => {
    setShowGoalModal(false);
  };

  return (
    <>
      <GoalModal
        show={showGoalModal}
        onCancel={hideGoalModal}
        header="What's your goal for this Message?"
        onClick={hideModalOnLinkClick}
      ></GoalModal>

      <div className="profile_container">
        <ProfileButton {...props}></ProfileButton>
        <SquareButton
          href={`https://brisk-experiences.typeform.com/to/X3iOtWFh#userid=${props.userId}&firstname=${props.userFirstName}`}
        >
          <FaGift />
        </SquareButton>
        <SquareButton
          customButton="inverted"
          customText="compose"
          onClick={clickedHandler}
        >
          <FaPen className="react-icons slight-padding" />
        </SquareButton>
      </div>
    </>
  );
};

export default NavButtonsTop;
