import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import {
	FaStar,
	// FaSitemap,
	FaReply,
	FaSyncAlt,
	FaUnlink,
	FaLink,
} from 'react-icons/fa';

import { GroupContext } from '../../shared/context/group-context';
import Input from '../../shared/components/FormElements/Input';
import { useForm } from '../../shared/hooks/form-hook';
import { VALIDATOR_MINLENGTH } from '../../shared/util/validators';
import Button from '../../shared/components/FormElements/Button';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { useAuth } from '../../shared/hooks/auth-hook';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import MessageTemplateList from '../../message/components/MessageTemplateList';
import * as TimeAgo from '../../shared/util/TimeAgo';

import './ReviewItem.css';
// import Emoji from "../../shared/components/FormElements/Emoji";

const ReviewItem = (props) => {
	const {
		groups: { selectedGroup },
	} = useContext(GroupContext);

	const { location } = selectedGroup.groupConnections.googleMyBusiness || {
		location: {},
	};

	const { userId, userFirstName, accountId } = useAuth();

	const [replyClicked, setReplyClicked] = useState(false);
	const [editClicked, setEditClicked] = useState(false);
	const [review, setReview] = useState(props);

	const { isLoading, isError, sendRequest, clearError } = useHttpClient();
	// TODO: implement modal to catch isError and clearError functions to improve UX.
	const [formState, inputHandler, setFormData] = useForm(
		{
			reviewResponse: {
				value: '',
				isValid: false,
			},
		},
		false
	);

	function setNativeValue(element, value) {
		const valueSetter = Object.getOwnPropertyDescriptor(element, 'value').set;
		const prototype = Object.getPrototypeOf(element);
		const prototypeValueSetter = Object.getOwnPropertyDescriptor(
			prototype,
			'value'
		).set;

		if (valueSetter && valueSetter !== prototypeValueSetter) {
			prototypeValueSetter.call(element, value);
		} else {
			valueSetter.call(element, value);
		}
	}

	const templateClickHandler = (storedContent) => {
		const textarea = document.querySelector('#reviewResponse');
		setNativeValue(textarea, storedContent);
		textarea.dispatchEvent(new Event('input', { bubbles: true }));
		setFormData(
			{
				reviewResponse: {
					value: formState.inputs.reviewResponse.value,
					isValid: true,
				},
			},
			true
		);
	};

	const reviewItemDate = new Date(review.date).getTime();
	// console.log("Time: " + feedbackItemDate);

	const dt = TimeAgo.inWords(reviewItemDate, new Date());
	// console.log(dt);

	const responseSubmitHandler = async (event) => {
		event.preventDefault();

		const reviewerName = review.reviewer.split(' ');

		let processedMessage = formState.inputs.reviewResponse.value
			.replace('--CustomerFirstName--', reviewerName[0])
			.replace('--CustomerLastName--', reviewerName[1] || '')
			.replace('--StarRating--', String(review.rating))
			.replace('--GroupName--', selectedGroup.groupName)
			.replace('--MyFirstName--', userFirstName)
			.replace('--MyEmail--', userFirstName)
			.replace('--ReviewLink--', selectedGroup.groupReviewLink);

		const id =
			Array.from(event.target.elements)[0].getAttribute('reviewid') || false;
		const reply = {
			value: processedMessage || '',
			id,
		};
		try {
			const { data } = await sendRequest(
				`${process.env.REACT_APP_BACKEND_URL}/auth/urlGoogle/reviews/${location.id}/${selectedGroup.id}/${userId}`,
				'put',
				JSON.stringify({
					reply,
				}),
				{ 'Content-Type': 'application/json' }
			);
			setReview((review) => ({
				...review,
				response: data.reviewResponse,
				responded: data.reviewResponded,
			}));
			setEditClicked(false);
			setReplyClicked(false);
			props.setDocumentCount({
				all: Number(props.documentCount.all),
				unresponded:
					props.filter === 'unresponded'
						? Number(props.documentCount.unresponded) - 1
						: Number(props.documentCount.unresponded),
			});
		} catch (err) {
			console.log('Error sending reply: ', err);
		}
	};

	const replyButtonHandler = () => {
		setEditClicked(false);
		setReplyClicked(true);
	};

	const cancelButtonHandler = () => {
		setEditClicked(false);
		setReplyClicked(false);
	};

	const editButtonHandler = () => {
		console.log('Edit clicked...');
		setEditClicked(true);
		setReplyClicked(true);
	};

	return (
		<React.Fragment>
			{/* <Modal
        show={showTemplateModal}
        onCancel={hideTemplateModal}
        header={`Templates`}
        description={`Speed up your responses! Select a template to use in your reply...`}
        // footer={
        //   <React.Fragment>
        //     <Button
        //       onClick={successGoBackHandler}
        //       buttonMargin="btn--14px--right"
        //     >
        //       Go Back
        //     </Button>
        //     <Button
        //       onClick={hideSuccessModal}
        //       buttonStyle="btn--default--dark"
        //       buttonCustom="btn--wide"
        //     >
        //       Send Another
        //     </Button>
        //   </React.Fragment>
        // }
      >
        {loadedTemplates &&
          loadedTemplates.map((template) => {
            return <li>{template.templateTitle}</li>;
          })}
      </Modal> */}
			<div
				className={`tile_default ${
					replyClicked ? 'lesser_padding_bottom_click' : null
				}`}
			>
				<div className='top-sec-review-tile'>
					<div className='team_info _50_opacity'>
						<div className='team_id margin-right'>
							{/* <FaSitemap /> */}

							{location.name ? (
								<FaLink />
							) : (
								// <Emoji
								//   // customClass="profile-button"
								//   label="link"
								//   symbol="🔗"
								// />
								<FaUnlink />
							)}
						</div>
						<Link
							to={`/connect/${accountId}/${selectedGroup.id}/google-my-business`}
							className='team_id text'
						>
							{location.name
								? location.name
								: 'Click here to reconnect Google My Business'}
						</Link>
					</div>
					<div className='time_stamp'>
						<div className='time_stamp_text _50_opacity'>{dt}</div>
					</div>
				</div>
				<div className='review_icon_name_star_rating'>
					<div className='team_info'>
						<div className='dynamic_icons google' />
					</div>
					<h4 className='reviewer'>{review.reviewer}</h4>
					<div className='google_rating'>
						{[...Array(5)].map((x, i) => {
							//console.log(review.rating > i, i);
							return (
								<div className='reviewStar' key={i}>
									<FaStar
										key={i}
										color={review.rating > i ? '#F9B404' : '#E7E7E7'}
									/>
								</div>
							);
						})}
					</div>
				</div>
				<div className='customer_comment max_width'>{review.body}</div>
				<div className='subtle_dividing_line' />
				{review.responded === false && (
					<>
						<form className='reply-box' onSubmit={responseSubmitHandler}>
							<React.Fragment>
								{/* <div className="reply-box"> */}
								{replyClicked === true ? (
									<Input
										id='reviewResponse'
										reviewid={review.reviewid}
										element='respond'
										type='text'
										autoFocus='true'
										placeholder='Type your response...'
										validators={[VALIDATOR_MINLENGTH(6)]}
										errorText='Please write a review response with at least 12 characters.'
										inputClass='dynamic_message_reply connected'
										onInput={inputHandler}
										togglesClass='disable-settings-toggles'
									/>
								) : null}

								<div>
									<Button
										type='submit'
										onClick={replyButtonHandler}
										// buttonSize={replyClicked === false ? null : "btn--default"}
										buttonSize='btn--default'
										buttonStyle={
											replyClicked === false ? null : 'btn--default--dark'
										}
										buttonCustom={
											replyClicked === true && 'btn--respond--active'
										}
										disabled={
											replyClicked === false ? false : !formState.isValid
										}
									>
										{isLoading ? (
											<LoadingSpinner />
										) : (
											<>
												<div className='icon_header_button'>
													<FaReply className='react-icons' />
												</div>
												Reply
											</>
										)}
									</Button>
									{replyClicked && (
										<Button
											type='button'
											buttonSize='btn--default'
											buttonCustom='btn--respond--cancel'
											onClick={cancelButtonHandler}
										>
											Cancel
										</Button>
									)}
								</div>
							</React.Fragment>
						</form>

						{replyClicked && (
							<div className='template-list'>
								<MessageTemplateList
									templates={true}
									loadedTemplates={review.templates}
									templateClick={(content) => templateClickHandler(content)}
								></MessageTemplateList>
							</div>
						)}
					</>
				)}
				{review.responded === true && (
					<>
						<form className='reply-box' onSubmit={responseSubmitHandler}>
							{editClicked === false && (
								<>
									<div className='dynamic_message_reply'>
										<p className='no-margin-bottom'>
											<span className='margin-right-span'>
												Response from the owner:
											</span>
											{review.response}
										</p>
									</div>

									<Button
										type='submit'
										onClick={editButtonHandler}
										buttonSize='btn--default'
										buttonCustom={'btn--respond--default'}
										// disabled={replyClicked === false ? false : !formState.isValid}
									>
										Edit
									</Button>
								</>
							)}
							{editClicked === true && (
								<>
									<Input
										id='reviewResponse'
										element='respond'
										type='text'
										reviewid={review.reviewid}
										// placeholder="Type your response..."
										validators={[VALIDATOR_MINLENGTH(6)]}
										errorText='Please write a review response with at least 12 characters.'
										inputClass='dynamic_message_reply connected'
										onInput={inputHandler}
										togglesClass='disable-settings-toggles'
										initialValue={review.response}
										initialValid={true}
									/>
									<div>
										<Button
											type='submit'
											buttonSize='btn--default'
											buttonStyle={editClicked ? 'btn--default--dark' : null}
											buttonCustom={
												replyClicked === true && 'btn--respond--active'
											}
											disabled={
												replyClicked === false ? false : !formState.isValid
											}
										>
											{isLoading ? (
												<LoadingSpinner />
											) : (
												<>
													<div className='icon_header_button'>
														<FaSyncAlt className='react-icons' />
													</div>
													Save
												</>
											)}
										</Button>
										{editClicked && (
											<Button
												type='button'
												buttonSize='btn--default'
												buttonCustom='btn--respond--cancel'
												onClick={cancelButtonHandler}
											>
												Cancel
											</Button>
										)}
									</div>
								</>
							)}
						</form>
						{replyClicked && (
							<div className='template-list'>
								<MessageTemplateList
									templates={true}
									loadedTemplates={review.templates}
									templateClick={(content) => templateClickHandler(content)}
								></MessageTemplateList>
							</div>
						)}
					</>
				)}
			</div>
		</React.Fragment>
	);
};

export default ReviewItem;
