import React, { useState } from "react";
import { FaPlus } from "react-icons/fa";

import Button from "../../shared/components/FormElements/Button";
import GoalModal from "../../shared/components/UIElements/GoalModal";

const NoticeNoHistory = () => {
  const [showGoalModal, setShowGoalModal] = useState(false);

  const hideGoalModal = () => {
    setShowGoalModal(false);
  };

  const clickedHandler = () => {
    setShowGoalModal(true);
  };

  const hideModalOnLinkClick = () => {
    setShowGoalModal(false);
  };

  return (
    <React.Fragment>
      <GoalModal
        show={showGoalModal}
        onCancel={hideGoalModal}
        header="What's your goal for this Message?"
        onClick={hideModalOnLinkClick}
      ></GoalModal>

      <div className="container_state_notice">
        <div className="notification_tile feedback">
          <div className={`notification_img_cont`}>
            <FaPlus />
          </div>
          <div className="notification_text_wrapper">
            <h3 className="notification_header_black">
              No message history just yet...
            </h3>
            <div className="notification_sub_text max_width">
              You'll see a list of messages or emails you've recently sent to
              customer. Create new message now?
              {/* <a href="/guide-link" className="embedded_text_link">
              <span>Learn More</span>
            </a> */}
            </div>
          </div>
          <Button
            type="button"
            onClick={clickedHandler}
            buttonCustom="btn--notice"
          >
            Create Message
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default NoticeNoHistory;
