import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FaRegSmile, FaRegMeh, FaRegFrown, FaSearch } from 'react-icons/fa';
import FeedbackList from '../components/FeedbackList';
import { useHttpClient } from '../../shared/hooks/http-hook';
import TableNavigation from '../../shared/components/Navigation/TableNavigation';
import Button from '../../shared/components/FormElements/Button';
import DateDropDown from '../../shared/components/FormElements/DateDropDown';
import NoticeImportingFeedback from '../components/NoticeImportingFeedback';

const Feedback = () => {
	const groupId = useParams().groupId;
	const [loadedFeedback, setLoadedFeedback] = useState([]);
	const [searchResults, setSearchResults] = useState(loadedFeedback);
	// const [tInterval, setTInterval] = useState(0);
	const [documents, setDocuments] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [feedbackPerPage] = useState(20);
	const { isLoading, sendRequest } = useHttpClient();
	const [queryUrl, setQueryUrl] = useState(``);
	const [sentiment, setSentiment] = useState('all');
	const [dateRange, setDateRange] = useState(0);
	const [searchTerm, setSearchTerm] = useState('');
	const initCountState = {
		all: 0,
		promoters: 0,
		passives: 0,
		detractors: 0,
	};
	const [documentCount, setDocumentCount] = useState(initCountState);

	// const fetchFeedbackPoling = async () => {
	//   try {
	//     const responseData = await sendRequest(
	//       `${process.env.REACT_APP_BACKEND_URL}/feedback/group/${groupId}?page=${currentPage}&limit=${feedbackPerPage}`
	//     );
	//     setLoadedFeedback((prev) => [...prev, ...responseData.results]);

	//   } catch (err) {}
	// };

	// let startDate;
	// let endDate;

	// useInputSearch(
	//   // { currentPage: currentPage },
	//   { documentLimit: feedbackPerPage },
	//   searchQuery,
	//   { currentPage: feedbackPerPage },
	//   { url: queryUrl }
	// );

	const queryUrlHandler = async (event, curPage) => {
		if (event !== undefined) {
			setSentiment(event.target.id);
			setCurrentPage(1);
			setQueryUrl(
				`${process.env.REACT_APP_BACKEND_URL}/feedback/group/${groupId}?page=1&limit=${feedbackPerPage}&sentiment=${event.target.id}&date=${dateRange}`
			);
		} else {
			setQueryUrl(
				`${process.env.REACT_APP_BACKEND_URL}/feedback/group/${groupId}?page=${curPage}&limit=${feedbackPerPage}&sentiment=${sentiment}&date=${dateRange}`
			);
		}
	};

	useEffect(() => {
		if (queryUrl.trim() !== '') {
			setQueryUrl((url) => url.split('date=').shift() + `date=${dateRange}`);
		} else {
			setQueryUrl(
				`${process.env.REACT_APP_BACKEND_URL}/feedback/group/${groupId}?page=${currentPage}&limit=${feedbackPerPage}&sentiment=${sentiment}&date=${dateRange}`
			);
		}
	}, [dateRange]);

	useEffect(() => {
		const fetchFeedback = async () => {
			try {
				const responseData = await sendRequest(
					queryUrl.trim() !== '' && queryUrl.includes(groupId)
						? queryUrl
						: `${process.env.REACT_APP_BACKEND_URL}/feedback/group/${groupId}?page=${currentPage}&limit=${feedbackPerPage}&sentiment=${sentiment}&date=${dateRange}`
				);
				setDocumentCount(
					responseData?.initCountState
						? responseData?.initCountState
						: initCountState
				);
				setLoadedFeedback(responseData?.results ? responseData?.results : []);
				// setFeedbackState(responseData?.results ? responseData?.results : []);
				setDocuments(responseData?.documents ? responseData?.documents : 0);
			} catch (err) {
				setLoadedFeedback([]);
				// setFeedbackState([]);
				setDocuments(0);
				setDocumentCount(initCountState);
			}
		};
		fetchFeedback();
	}, [sendRequest, groupId, currentPage, queryUrl, sentiment]);

	const handleNextPage = () => {
		setCurrentPage((prev) => prev + 1);
		queryUrlHandler(undefined, currentPage + 1);
	};

	const handlePreviousPage = () => {
		setCurrentPage((prev) => prev - 1);
		queryUrlHandler(undefined, currentPage - 1);
	};

	const calcLastPage = Math.ceil(documents / feedbackPerPage);

	const handleSearchOnChange = (e) => {
		setSearchTerm(e.target.value.toLowerCase());
		setSearchResults(
			loadedFeedback.filter((feedback) =>
				feedback.feedbackCustomer
					.toLowerCase()
					.includes(e.target.value.toLowerCase())
			)
		);
	};

	// let buttonA = "promoters";
	// let buttonB = "passives";
	// let buttonC = "detractors";

	// const sentimentClickHandler = (event) => {
	//   // console.log("Clicked:", event);
	//   if (event.target.id === "promoters") {
	//     const fetchPromoters = async () => {
	//       try {
	//         const responseData = await sendRequest(
	//           `${process.env.REACT_APP_BACKEND_URL}/feedback/group/promoters/${groupId}?page=${currentPage}&limit=${feedbackPerPage}`
	//         );
	//         setLoadedFeedback(responseData.results);
	//         setDocuments(responseData.documents);
	//       } catch (err) {}
	//     };

	//     fetchPromoters();

	//     document.getElementById(event.target.id).className = "btn--default--dark";
	//   }
	// };

	// const handleQueryValue = (e) => {
	//   e.preventDefault();
	//   setSearchQuery(e.target.value);
	//   setCurrentPage(1);
	// };

	return (
		<div>
			{/* <ErrorModal error={isError} onClear={clearError} /> */}
			{/* <NoticeFeedback /> */}

			{/* {isLoading && <LoadingSpinner dark="dark" />} */}

			<div className='filter_bubbles_wrapper'>
				<div className='left_side'>
					<Button
						id='all'
						onClick={queryUrlHandler}
						buttonSize='btn--default'
						buttonStyle={sentiment === 'all' && 'btn--default--dark'}
						buttonMargin='btn--14px--right'
					>
						{/* <div className="icon_header_button">
                  {" "}
                  <FaHashtag size="1.28em" className="react-icons" />{" "}
                </div>{" "} */}
						{loadedFeedback && `All (${documentCount.all})`}
					</Button>

					{/* <Button
						id='promoters'
						onClick={queryUrlHandler}
						buttonSize='btn--default'
						buttonStyle={sentiment === 'promoters' && 'btn--default--dark'}
						buttonMargin='btn--14px--right'
					>
						<div className='icon_header_button'>
							{' '}
							<FaRegSmile size='1.28em' className='react-icons' />{' '}
						</div>{' '}
						{`${documentCount.promoters} Positive`}
					</Button>
					<Button
						buttonSize='btn--default'
						buttonStyle={sentiment === 'passives' && 'btn--default--dark'}
						buttonMargin='btn--14px--right'
						id='passives'
						onClick={queryUrlHandler}
					>
						<div className='icon_header_button'>
							{' '}
							<FaRegMeh size='1.28em' className='react-icons' />{' '}
						</div>{' '}
						{`${documentCount.passives} Neutral`}
					</Button>

					<Button
						buttonSize='btn--default'
						buttonStyle={sentiment === 'detractors' && 'btn--default--dark'}
						buttonMargin='btn--14px--right'
						id='detractors'
						onClick={queryUrlHandler}
					>
						<div className='icon_header_button'>
							{' '}
							<FaRegFrown size='1.28em' className='react-icons' />{' '}
						</div>{' '}
						{`${documentCount.detractors} Negative`}
					</Button> */}

					<div className='container_nav_top'>
						<div className='search_input_cont margin-right'>
							<input
								id='messageCount'
								type='text'
								className='search_input'
								placeholder='Search by name...'
								onChange={handleSearchOnChange}
							/>
							<div className='search_icon_cont'>
								<FaSearch />
							</div>
						</div>
						<DateDropDown setDateRange={setDateRange} dateRange={dateRange} />
					</div>
				</div>
			</div>

			{isLoading && (
				<>
					<NoticeImportingFeedback />
				</>
			)}
			{!isLoading && loadedFeedback && (
				<>
					<FeedbackList
						items={searchTerm ? searchResults : loadedFeedback}
						group={groupId}
						sentiment={sentiment}
					/>

					{documents >= feedbackPerPage && (
						<TableNavigation
							onClickNext={handleNextPage}
							onClickPrevious={handlePreviousPage}
							firstPageNumber={String(currentPage)}
							lastPageNumber={String(calcLastPage)}
							disablePrev={currentPage === 1 ? true : false}
							disableNext={currentPage === calcLastPage ? true : false}
						/>
					)}
				</>
			)}
		</div>
	);
};

export default Feedback;
