import React, { useContext } from 'react';
import ReviewItem from './ReviewItem';
import NoticeGoogle from './NoticeGoogle';
import NoticeReviews from './NoticeReviews';
import NoticeUnresponded from './NoticeUnresponded';
import { GroupContext } from '../../shared/context/group-context';

const ReviewsList = (props) => {
	const { groups } = useContext(GroupContext);
	const isConnected =
		groups?.selectedGroup?.groupConnections?.googleMyBusiness?.isConnected;

	const loadedTemplates = props.templates;

	// if (props.filter === "unresponded" && props.documentCount.unresponded === 0) {
	//   return (
	//     <>
	//       <NoticeUnresponded />
	//     </>
	//   );
	// }

	// if (!isLoading && !socketLoading && loadedReviews.length === 0) {
	//   return (
	//     <>
	//       <NoticeGoogle />
	//       <NoticeReviews />
	//       {/* <NoticeFacebook /> */}
	//     </>
	//   );
	// }

	// if (props.isLoading) {
	//   return (
	//     <ContentLoader>
	//       <rect x="0" y="0" rx="5" ry="5" width="1000px" height="800px"></rect>
	//     </ContentLoader>
	//   );
	// }

	// if (props.filter === "unresponded") {
	//   return <NoticeUnresponded />;
	// }

	if (props.items.length === 0) {
		if (isConnected) {
			return (
				<>
					{' '}
					<NoticeUnresponded />
				</>
			);
		} else {
			return (
				<>
					<NoticeGoogle />
					<NoticeReviews />
				</>
			);
		}
	}

	// console.log("_____FILTER_____", props.filter);

	return (
		<div>
			{props.items.map((review) => {
				return (
					<ReviewItem
						documentCount={props.documentCount}
						setDocumentCount={props.setDocumentCount}
						filter={props.filter}
						templates={loadedTemplates}
						key={review._id}
						id={review._id}
						reviewid={review.reviewId}
						origin={review.reviewOrigin}
						date={review.reviewCreated}
						reviewer={review.reviewerName}
						rating={review.reviewRating}
						body={review.reviewBody}
						group={review.reviewGroup}
						responded={review.reviewResponded}
						response={review.reviewResponse}
					/>
				);
			})}
		</div>
	);
};

export default ReviewsList;
