import React from 'react';
import ReactDOM from 'react-dom';

import './styles/tailwind.css';
import './assets/css/webflow.css';
import './assets/css/getseen-two.webflow.css';
import './assets/css/normalize.css';

import App from './App';

ReactDOM.render(
	<App />,

	document.getElementById('main-view')
);
