import React, { useEffect, useState, useContext } from 'react';
import { useParams, withRouter } from 'react-router-dom';

import { useForm } from '../../shared/hooks/form-hook';
import { useHttpClient } from '../../shared/hooks/http-hook';
import Input from '../../shared/components/FormElements/Input';
import { AuthContext } from '../../shared/context/auth-context';

import ModalView from '../../shared/components/Navigation/ModalView';
import ModalHeader from '../../shared/components/Navigation/ModalHeader';
import Button from '../../shared/components/FormElements/Button';
import FormButtonWrapper from '../../shared/components/FormElements/FormButtonWrapper';
import CancelButton from '../../shared/components/FormElements/CancelButton';
import { VALIDATOR_SWITCH } from '../../shared/util/validators';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';

const UserNotifications = (props) => {
	const auth = useContext(AuthContext);
	const userId = useParams().userId;
	const [loadedUserNotifications, setLoadedUserNotifications] = useState();
	// const [
	//   loadedNotificationPreferences,
	//   setLoadedNotificationPreferences,
	// ] = useState();

	const { isLoading, isError, sendRequest } = useHttpClient();

	const [formState, inputHandler, setFormData] = useForm(
		{
			oneStar: {
				value: true || false,
				isValid: true,
			},
			twoStar: {
				value: true || false,
				isValid: true,
			},
			threeStar: {
				value: true || false,
				isValid: true,
			},
			fourStar: {
				value: true || false,
				isValid: true,
			},
			fiveStar: {
				value: true || false,
				isValid: true,
			},
			promoters: {
				value: true || false,
				isValid: true,
			},
			passives: {
				value: true || false,
				isValid: true,
			},
			detractors: {
				value: true || false,
				isValid: true,
			},
		},
		true
	);

	useEffect(() => {
		const fetchUserNotifications = async () => {
			try {
				const responseData = await sendRequest(
					`${process.env.REACT_APP_BACKEND_URL}/users/user/${userId}`
				);

				setLoadedUserNotifications(responseData.user.userNotifications[0]);

				setFormData(
					{
						oneStar: {
							value: responseData.user.userNotifications[0].oneStar,
							isValid: true,
						},
						twoStar: {
							value: responseData.user.userNotifications[0].twoStar,
							isValid: true,
						},
						threeStar: {
							value: responseData.user.userNotifications[0].threeStar,
							isValid: true,
						},
						fourStar: {
							value: responseData.user.userNotifications[0].fourStar,
							isValid: true,
						},
						fiveStar: {
							value: responseData.user.userNotifications[0].fiveStar,
							isValid: true,
						},
						promoters: {
							value: responseData.user.userNotifications[0].promoters,
							isValid: true,
						},
						passives: {
							value: responseData.user.userNotifications[0].passives,
							isValid: true,
						},
						detractors: {
							value: responseData.user.userNotifications[0].detractors,
							isValid: true,
						},
					},
					true
				);
			} catch (err) {}
		};
		fetchUserNotifications();
	}, [sendRequest, userId, setFormData]);

	// if (isLoading) {
	//   return (
	//     <div className="center">
	//       <LoadingSpinner dark="dark" />
	//     </div>
	//   );
	// }

	// if (!loadedUserNotifications && !isError) {
	//   return (
	//     <div className="center">
	//       <h2>Could not find your notification preferences.</h2>
	//     </div>
	//   );
	// }

	const notificationsUpdateHandler = async (event) => {
		event.preventDefault();
		try {
			await sendRequest(
				`${process.env.REACT_APP_BACKEND_URL}/users/notifications/${userId}`,
				'PATCH',
				JSON.stringify({
					oneStar: formState.inputs.oneStar.value,
					twoStar: formState.inputs.twoStar.value,
					threeStar: formState.inputs.threeStar.value,
					fourStar: formState.inputs.fourStar.value,
					fiveStar: formState.inputs.fiveStar.value,
					promoters: formState.inputs.promoters.value,
					passives: formState.inputs.passives.value,
					detractors: formState.inputs.detractors.value,
				}),
				{
					'Content-Type': 'application/json',
				}
			);
			props.history.goBack();
		} catch (err) {
			console.log(err);
		}
	};

	return (
		//TODO Render real user data
		<ModalView goBack={`/${auth.accountId}/${auth.groupId}/settings`}>
			<ModalHeader
				pageTitle='My Notifications'
				pageSubTitle='Here are your user notification preferences 🔔.'
			>
				<Button to={`/${auth.accountId}/${auth.userId}/my-preferences`}>
					Disable All Notifications
				</Button>
			</ModalHeader>
			{isLoading && <LoadingSpinner dark='dark' />}
			{loadedUserNotifications && (
				<form onSubmit={notificationsUpdateHandler}>
					<Input
						id='oneStar'
						element='switch'
						onColor='#4EB0AE'
						label='1-Star ⭐️'
						validators={[VALIDATOR_SWITCH()]}
						description="We'll notify you via email when you receive a one star review on Google."
						initialValue={loadedUserNotifications.oneStar}
						initialValid={true}
						onInput={inputHandler}
					/>

					<Input
						id='twoStar'
						element='switch'
						onColor='#4EB0AE'
						label='2-Stars ⭐️ ⭐️'
						validators={[VALIDATOR_SWITCH()]}
						description="We'll notify you via email when you receive a two star review on Google."
						initialValue={loadedUserNotifications.twoStar}
						initialValid={true}
						onInput={inputHandler}
					/>

					<Input
						id='threeStar'
						element='switch'
						onColor='#4EB0AE'
						label='3-Stars ⭐️ ⭐️ ⭐️'
						validators={[VALIDATOR_SWITCH()]}
						description="We'll notify you via email when you receive a three star review on Google."
						initialValue={loadedUserNotifications.threeStar}
						initialValid={true}
						onInput={inputHandler}
					/>

					<Input
						id='fourStar'
						element='switch'
						onColor='#4EB0AE'
						label='4-Stars ⭐️ ⭐️ ⭐️ ⭐️'
						validators={[VALIDATOR_SWITCH()]}
						description="We'll notify you via email when you receive a four star review on Google."
						initialValue={loadedUserNotifications.fourStar}
						initialValid={true}
						onInput={inputHandler}
					/>

					<Input
						id='fiveStar'
						element='switch'
						onColor='#4EB0AE'
						label='5-Stars ⭐️ ⭐️ ⭐️ ⭐️ ⭐️'
						validators={[VALIDATOR_SWITCH()]}
						description="We'll notify you via email when you receive a five star review on Google."
						initialValue={loadedUserNotifications.fiveStar}
						initialValid={true}
						onInput={inputHandler}
					/>

					<Input
						id='promoters'
						element='switch'
						onColor='#4EB0AE'
						label='Positive 🙂'
						validators={[VALIDATOR_SWITCH()]}
						description='Receive email notifications for each positive (5) star feedback rating.'
						initialValue={loadedUserNotifications.promoters}
						initialValid={true}
						onInput={inputHandler}
					/>

					<Input
						id='passives'
						element='switch'
						onColor='#4EB0AE'
						label='Neutral 😐'
						validators={[VALIDATOR_SWITCH()]}
						description='Receive email notifications for each neutral (4) star feedback rating.'
						initialValue={loadedUserNotifications.passives}
						initialValid={true}
						onInput={inputHandler}
					/>
					<Input
						id='detractors'
						element='switch'
						onColor='#4EB0AE'
						label='Negative 🙁'
						validators={[VALIDATOR_SWITCH()]}
						description='Receive email notifications for each negative (1-3) star feedback rating.'
						initialValue={loadedUserNotifications.detractors}
						initialValid={true}
						onInput={inputHandler}
					/>

					<FormButtonWrapper>
						<CancelButton to={`/${auth.accountId}/${auth.groupId}/settings`} />
						<Button
							type='submit'
							buttonStyle='btn--default--dark'
							buttonSize='btn--small'
							buttonCustom='btn--wide'
						>
							Save
						</Button>
					</FormButtonWrapper>
				</form>
			)}
		</ModalView>
	);
};

export default withRouter(UserNotifications);
