import React from "react";
// import { Link } from "react-router-dom";

const SquareButton = (props) => {
  if (props.href) {
    return (
      <a
        href={props.href}
        className={`round_button_nav ${props.customButton}`}
        target="_blank"
      >
        <div className={`present_text ${props.customText}`}>
          {props.children}
        </div>
      </a>
    );
  } else if (props.onClick) {
    return (
      <button
        type="button"
        onClick={props.onClick}
        className={`round_button_nav ${props.customButton}`}
      >
        <div className={`present_text ${props.customText}`}>
          {props.children}
        </div>
      </button>
    );
  }
};

export default SquareButton;
