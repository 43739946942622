import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

import ConnectItem from '../components/ConnectItem';
import NoticeConnect from '../components/NoticeConnect';
import { GroupContext } from '../../shared/context/group-context';

const Connect = (props) => {
	const groupId = useParams().groupId;
	const accountId = useParams().accountId;
	const {
		groups: {
			selectedGroup: { groupConnections },
		},
	} = useContext(GroupContext);
	console.log({ groupConnections });

	const gmbStatus = groupConnections.googleMyBusiness.isConnected;

	const reportingStatus =
		groupConnections.reporting.reportUrl.length > 10 && true;

	const typeformStatus = groupConnections.typeform.isConnected;

	return (
		<>
			<NoticeConnect />
			<ConnectItem
				id='sms-settings'
				toPath={`/connect/${groupId}/sms-settings`}
				name='SMS Settings'
				iconClass='nps'
				available={true}
				connected={true}
			/>

			<ConnectItem
				id='gmb'
				toPath={`/connect/${accountId}/${groupId}/google-my-business`}
				name='Google My Business'
				iconClass='gmb'
				available={true}
				connected={gmbStatus || false}
			/>
			<ConnectItem
				id='reports'
				toPath={`/connect/${groupId}/reports`}
				name='Reporting'
				iconClass='reports'
				available={true}
				connected={reportingStatus || false}
				{...reportingStatus}
			/>
			<ConnectItem
				id='typeform'
				toPath={`/connect/${groupId}/feedback-id`}
				name='Feedback'
				iconClass='nps'
				available={true}
				connected={typeformStatus || false}
			/>

			<ConnectItem
				id='email'
				toPath={`/connect/${groupId}/email`}
				name='Email'
				iconClass='email'
				available={false}
				connected={false}
			/>

			<ConnectItem
				id='facebook'
				toPath={`/connect/${groupId}/facebook-recommendations`}
				name='Facebook'
				iconClass='facebook'
				available={false}
				connected={false}
			/>
			{/* TODO Build Facebook Manage / Setup connection container */}

			<ConnectItem
				id='zapier'
				toPath={`/connect/${groupId}/zapier`}
				name='Zapier'
				iconClass='zapier'
				available={false}
				connected={false}
			/>
		</>
	);
};

export default Connect;
