import React from "react";
import { NavLink } from "react-router-dom";
import { FaFileAlt, FaUserPlus, FaChartBar } from "react-icons/fa";

const NavLinksBottom = (props) => {
  // console.log({ props });

  const handleDisabledClick = () => {
    alert(
      "Please click on 'Connect' and then click on 'Reporting'. Here you can request custom reports."
    );
  };

  return (
    <div className="nav_links_cont">
      <NavLink
        to={`/${props.accountId}/templates`}
        className="navbar-button w-inline-block"
        activeClassName="navbar-button--current"
        exact={true}
      >
        <div className="navigation-icon">
          <FaFileAlt />
        </div>
        <div className="header_link">Templates</div>
      </NavLink>
      <NavLink
        to={`/${props.accountId}/${props.groupId}/users`}
        className="navbar-button w-inline-block"
        activeClassName="navbar-button--current"
        exact={true}
      >
        <div className="navigation-icon">
          <FaUserPlus />
        </div>
        <div className="header_link">Members</div>
      </NavLink>

      <a
        href={props.reportUrl || `#`}
        target={props.reportUrl ? "_blank" : null}
        className={`navbar-button ${
          props.reportUrl ? "" : "disabled"
        } w-inline-block`}
        onClick={props.reportUrl ? null : handleDisabledClick}
      >
        <div className="navigation-icon">
          <FaChartBar />
        </div>
        <div className="header_link">Reports</div>
      </a>
    </div>
  );
};

export default NavLinksBottom;
