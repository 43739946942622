import React, { useContext, useState, useEffect } from 'react';

import { AuthContext } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';
import MessageTemplateButton from './MessageTemplateButton';

function MessageTemplateList(props) {
	const tag = props.tag;
	const auth = useContext(AuthContext);
	const [loadedTemplates, setLoadedTemplates] = useState([]);
	const { isLoading, sendRequest } = useHttpClient();

	useEffect(() => {
		const fetchTemplates = async () => {
			try {
				const responseData = await sendRequest(
					`${process.env.REACT_APP_BACKEND_URL}/templates/account/${auth.accountId}?tag=${tag}`
				);

				setLoadedTemplates(responseData.accountTemplates || null);
			} catch (err) {
				console.log('Error: ', err);
			}
		};
		if (props.templates) {
			setLoadedTemplates(props.loadedTemplates);
		} else {
			fetchTemplates();
		}
	}, [sendRequest, props.loadedTemplates]);

	console.log({ loadedTemplates });

	// TODO: add 2nd click function to remove messageBody from textarea

	return (
		<React.Fragment>
			{!isLoading &&
				loadedTemplates?.map((template) => {
					return (
						<>
							<MessageTemplateButton
								key={template._id}
								id={template._id}
								onClick={(e) => {
									e.preventDefault();
									const storedContent = template.templateContent;
									props.templateClick(storedContent);
								}}
							>
								{template.templateTitle.length >= 28
									? template.templateTitle.substring(0, 28) + '...'
									: template.templateTitle}
							</MessageTemplateButton>
							{/* <Button href={`#`}
              buttonCustom={`btn--wide`}>
                <FaPlus />
              </Button> */}
						</>
					);
				})}
		</React.Fragment>
	);
}

export default MessageTemplateList;
