import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import ModalView from "../../shared/components/Navigation/ModalView";
import UsersList from "../components/UsersList";
import ModalHeader from "../../shared/components/Navigation/ModalHeader";
import Button from "../../shared/components/FormElements/Button";
import Input from "../../shared/components/FormElements/Input";
import { VALIDATOR_EMAIL } from "../../shared/util/validators";
import CancelButton from "../../shared/components/FormElements/CancelButton";
import { useForm } from "../../shared/hooks/form-hook";
import { useHttpClient } from "../../shared/hooks/http-hook";
import FormButtonWrapper from "../../shared/components/FormElements/FormButtonWrapper";
import Modal from "../../shared/components/UIElements/Modal";

import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import { AuthContext } from "../../shared/context/auth-context";
import { GroupContext } from "../../shared/context/group-context";

const User = () => {
  const {
    groups: { selectedGroup },
  } = useContext(GroupContext);
  const auth = useContext(AuthContext);
  const [loadedUsers, setLoadedUsers] = useState([]);
  const { isLoading, isError, sendRequest, clearError } = useHttpClient();
  const [showSuccess, setShowSuccess] = useState(false);
  const history = useHistory();

  const showSuccessModal = () => {
    setShowSuccess(true);
  };
  const hideSuccessModal = () => {
    setShowSuccess(false);
  };
  const successGoBackHandler = () => {
    setShowSuccess(false);
    history.push("/" + auth.accountId + "/" + selectedGroup.id + "/users");
  };

  const [formState, inputHandler] = useForm(
    {
      invitedEmail: {
        value: "",
        isValid: false,
      },
    },
    false
  );

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/users/group/${selectedGroup.id}`
        );

        setLoadedUsers(responseData.groupUsers);
      } catch (err) {}
    };
    fetchUsers();
  }, [sendRequest, selectedGroup.id]);

  const userAddHandler = async (event) => {
    event.preventDefault();
    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/invite-user`,
        "POST",
        JSON.stringify({
          invitedEmail: formState.inputs.invitedEmail.value,
          userId: auth.userId,
          groupId: selectedGroup.id,
          accountId: auth.accountId,
        }),
        { "Content-Type": "application/json" }
      );
    } catch (err) {}
    showSuccessModal();

    // history.push("/" + auth.accountId + selectedGroup._id + "/users");
  };
  return (
    <ModalView goBack={`/${auth.accountId}/${auth.groupId}/settings`}>
      <ErrorModal show={isError} onClear={clearError} />

      <Modal
        show={showSuccess}
        onCancel={hideSuccessModal}
        header="Invite sent!"
        description={`Invite sent successfully. An email to join ${selectedGroup.groupName} is waiting in their inbox.`}
        footer={
          <React.Fragment>
            {/* <Button onClick={hideReturnWarning} buttonMargin="btn--14px--right">
              Cancel
            </Button> */}
            <Button onClick={successGoBackHandler} buttonCustom="btn--wide">
              Okay
            </Button>
          </React.Fragment>
        }
      ></Modal>
      <ModalHeader
        pageTitle="Members"
        pageSubTitle={`of ${selectedGroup.groupName}.`}
      >
        {/* TODO implement dropdown location selection for Member page */}
        {auth.userIsAdmin === true && (
          <Button to={"/" + auth.accountId + "/groups"}>Manage Groups</Button>
        )}
      </ModalHeader>
      {/* {loadedUsers.length === 1 ? <NoticeUsersEmpty /> : null} */}
      <form onSubmit={userAddHandler}>
        <Input
          id="invitedEmail"
          element="input"
          type="email"
          // autoFocus="true"
          label="Invite Team Members"
          description={`We'll send a friendly 📧 to join ${selectedGroup.groupName}.`}
          placeholder="name@example.com"
          validators={[VALIDATOR_EMAIL()]}
          errorText="Please enter a correct email we can send an invite to."
          onInput={inputHandler}
        />
        <FormButtonWrapper>
          <CancelButton to={`/${auth.accountId}/${auth.groupId}/settings`} />
          <Button
            type="submit"
            buttonStyle="btn--default--dark"
            buttonSize="btn--small"
            buttonCustom="btn--wide"
            disabled={!formState.isValid}
          >
            Invite Member
          </Button>
        </FormButtonWrapper>
      </form>
      {isLoading && (
        <div className="center">
          {" "}
          <LoadingSpinner dark="dark" />
        </div>
      )}
      {!isLoading && loadedUsers && <UsersList items={loadedUsers} />}
    </ModalView>
  );
};

export default User;
