import React, { Suspense, useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';

import dotenv from 'dotenv';

import {
  disable as disableDarkMode,
  setFetchMethod,
} from 'darkreader';

import Members from './auth/containers/Members';
import AuthCallback from './auth/containers/AuthCallback';
import ResetPassword from './auth/containers/ResetPassword';
import ChangePassword from './auth/containers/ChangePassword';
import AddNewMember from './auth/containers/AddNewMember';
import MainView from './shared/components/Navigation/MainView';
import Reviews from './reviews/containers/Reviews';
import Feedback from './feedback/containers/Feedback';
import Connect from './connect/containers/Connect';
import Settings from './settings/containers/Settings';
import Groups from './groups/containers/Groups';
import Message from './message/containers/Message';
import Compose from './compose/containers/Compose';
import History from './history/containers/History';
import Templates from './templates/containers/Templates';
import User from './user/containers/User';
import LoadingSpinner from './shared/components/UIElements/LoadingSpinner';

import { AuthContext } from './shared/context/auth-context';
import { useAuth } from './shared/hooks/auth-hook';
import { CreateGroupContext } from './shared/context/group-context';
// import { ConnectionContext } from "./shared/context/connection-context";
// import { ConnectionAuth } from "./shared/hooks/connection-hook";
// import { GroupAuth } from "./shared/hooks/group-hook";
import MessageFeedback from './message/containers/MessageFeedback';
import MessageReview from './message/containers/MessageReview';
import UserNotifications from './user/containers/UserNotifications';

// import ConnectFeedback from "./connect/containers/ConnectFeedback";
// import ConnectGoogleMyBusiness from "./connect/containers/ConnectGoogleMyBusiness";
// import ConnectFacebook from "./connect/containers/ConnectFacebook";
// import GroupCreate from "./groups/containers/GroupCreate";
// import UserAccount from "./user/containers/UserAccount";
// import UserPreferences from "./user/containers/UserPreferences";
// import UserManage from "./user/containers/UserManage";
// import GroupManage from "./groups/containers/GroupManage";
// import TemplateCreate from "./templates/containers/TemplateCreate";
// import TemplateManage from "./templates/containers/TemplateManage";
import io from 'socket.io-client';
const socket = io(process.env.REACT_APP_SOCKET_URL, {
  // path: '/ffsocket',
});

dotenv.config();

const UserAccount = React.lazy(() => import('./user/containers/UserAccount'));
const UserManage = React.lazy(() => import('./user/containers/UserManage'));
const UserPreferences = React.lazy(() =>
  import('./user/containers/UserPreferences')
);
const GroupCreate = React.lazy(() => import('./groups/containers/GroupCreate'));
const GroupManage = React.lazy(() => import('./groups/containers/GroupManage'));
const ConnectSms = React.lazy(() => import('./connect/containers/ConnectSms'));
const ConnectGoogleMyBusiness = React.lazy(() =>
  import('./connect/containers/ConnectGoogleMyBusiness')
);
const ConnectReports = React.lazy(() =>
  import('./connect/containers/ConnectReports')
);
const ConnectTypeform = React.lazy(() =>
  import('./connect/containers/ConnectTypeform')
);
const ConnectFacebook = React.lazy(() =>
  import('./connect/containers/ConnectFacebook')
);
const TemplateCreate = React.lazy(() =>
  import('./templates/containers/TemplateCreate')
);
const TemplateManage = React.lazy(() =>
  import('./templates/containers/TemplateManage')
);

setFetchMethod(window.fetch);

const App = () => {
  // const [theme, setTheme] = useState("light");
  // const [darkMode, setDarkMode] = useState(false);

  const {
    token,
    userId,
    accountId,
    userIsAdmin,
    userGroups,
    groupId,
    userFirstName,
    userPreferences,
    login,
    logout,
    updateSelectedGroup,
    updateUserPreferences,
  } = useAuth();

  let routes;

  useEffect(
    () => disableDarkMode(),
    [userPreferences]
  );

  // Chat Widget...

  // Data needed: FN, LN,

  if (token && userId && accountId && userGroups && groupId) {
    routes = (
      <Switch>
        <MainView>
          <Route path={`/:accountId/:groupId/reviews`} exact={true}>
            <Reviews socket={socket} />
          </Route>
          <Route path={`/:accountId/:groupId/feedback`} exact={true}>
            <Feedback />
          </Route>
          <Route path={`/:accountId/:groupId/settings`} exact={true}>
            <Settings />
          </Route>
          <Route path={`/:accountId/:groupId/connect`} exact={true}>
            <Connect />
          </Route>
          <Route path={`/:accountId/:groupId/history`} exact={true}>
            <History />
          </Route>
          <Route path={`/:accountId/templates`} exact={true}>
            <Templates />
          </Route>
          <Route path={`/:accountId/template/create`} exact={true}>
            <TemplateCreate />
          </Route>
          <Route path={`/template/:templateId/manage`} exact={true}>
            <TemplateManage />
          </Route>

          <Route path={`/messages/:groupId/custom/create`} exact={true}>
            <Message />
          </Route>
          <Route path={`/compose`} exact={true}>
            <Compose />
          </Route>
          <Route path={`/messages/:groupId/feedback/create`} exact={true}>
            <MessageFeedback />
          </Route>
          <Route path={`/messages/:groupId/reviews/create`} exact={true}>
            <MessageReview />
          </Route>
          <Route path={`/:accountId/groups`} exact={true}>
            <Groups />
          </Route>
          <Route path={`/group/:accountId/create`} exact={true}>
            <GroupCreate />
          </Route>
          <Route path={`/group/:groupId/manage`} exact={true}>
            <GroupManage />
          </Route>
          <Route path={`/:accountId/:groupId/users`} exact={true}>
            <User />
          </Route>
          <Route path={`/user/:userId/manage`} exact={true}>
            <UserManage />
          </Route>
          <Route path={`/:accountId/:userId/my-account`} exact={true}>
            <UserAccount />
          </Route>
          <Route path={`/:accountId/:userId/my-preferences`} exact={true}>
            <UserPreferences />
          </Route>
          <Route path={`/:accountId/:userId/my-notifications`} exact={true}>
            <UserNotifications />
          </Route>

          <Route path={`/connect/:groupId/sms-settings`} exact={true}>
            <ConnectSms />
          </Route>
          <Route
            path={`/connect/:accountId/:groupId/google-my-business`}
            exact={true}
          >
            <ConnectGoogleMyBusiness socket={socket} />
          </Route>
          <Route path={`/connect/:groupId/reports`} exact={true}>
            <ConnectReports />
          </Route>
          <Route path={`/connect/:groupId/feedback-id`} exact={true}>
            <ConnectTypeform />
          </Route>
          <Route
            path={`/connect/:groupId/facebook-recommendations`}
            exact={true}
          >
            <ConnectFacebook />
          </Route>

          {new URLSearchParams(window.location).get('pathname') ===
            '/oauth2callback' ? (
            <Route path={`/oauth2callback`} exact>
              <AuthCallback />
            </Route>
          ) : (
            <Redirect to={`/${accountId}/${groupId}/reviews`} />
          )}
        </MainView>
      </Switch>
    );
  } else {
    routes = (
      <Switch>
        <Route path='/members' exact>
          <Members />
        </Route>
        <Route path='/accounts/reset-password' exact>
          <ResetPassword />
        </Route>
        <Route path='/accounts/confirm-password/:resetPasswordToken' exact>
          <ChangePassword />
        </Route>
        <Route path='/members/welcome/:userInviteToken/:invitedEmail' exact>
          <AddNewMember />
        </Route>

        <Route
          path={`/connect/:accountId/:groupId/google-my-business`}
          exact={true}
        >
          <ConnectGoogleMyBusiness socket={socket} />
        </Route>

        {new URLSearchParams(window.location).get('pathname') ===
          '/oauth2callback' ? (
          <Route path={`/oauth2callback`} exact>
            <AuthCallback />
          </Route>
        ) : (
          <Redirect to='/members' />
        )}
      </Switch>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        userId,
        token: token,
        accountId,
        userIsAdmin,
        userGroups,
        groupId,
        userFirstName,
        userPreferences,
        login,
        logout,
        updateSelectedGroup,
        updateUserPreferences,
      }}
    >
      <CreateGroupContext>
        <Router>
          <Suspense
            fallback={
              <div className='center'>
                <LoadingSpinner dark='dark' />
              </div>
            }
          >
            {routes}
          </Suspense>
        </Router>
      </CreateGroupContext>
    </AuthContext.Provider>
  );
};

export default App;
