import React, { useContext, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FaHistory } from 'react-icons/fa';

import Header from './Header';
import { AuthContext } from '../../context/auth-context';
import { useHttpClient } from '../../hooks/http-hook';
import { GroupContext } from '../../context/group-context';
import { ACTIONS } from '../../reducers/group-reducer';
import Button from '../FormElements/Button';
import Dropdown from '../FormElements/Dropdown';
import ErrorModal from '../UIElements/ErrorModal';
import LoadingSpinner from '../UIElements/LoadingSpinner';

const MainHeader = () => {
	const auth = useContext(AuthContext);
	// const { groups: thisContext } = useContext(GroupContext);
	// console.log("MainHeader.jsx", { auth });
	// console.log("MainHeader.jsx", { thisContext });

	const { groups, dispatch } = useContext(GroupContext);
	const [loadedUser, setLoadedUser] = useState();
	const [loadedAccount, setLoadedAccount] = useState();
	const [loadedGroups, setLoadedGroups] = useState();
	const { isLoading, isError, sendRequest, clearError } = useHttpClient();

	// provide dynamic App header title for each container in MainView
	const location = useLocation();
	const capitalize = (s) => {
		if (typeof s !== 'string') return '';
		return `${s.charAt(0).toUpperCase()}${s.slice(1)}`;
	};

	let currentPage = location.pathname.split('/');
	const [, a, b, c] = currentPage;
	// console.log(a, b, c);

	const pageTitle = () => {
		if (c === 'new') return `Add ${c} ${b}`;
		currentPage = currentPage.pop();
		return capitalize(currentPage);
	};

	const history = useHistory();
	const goTo = (id) => {
		history.push(`/${auth.accountId}/${id}/${currentPage}`);
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				const userData = await sendRequest(
					`${process.env.REACT_APP_BACKEND_URL}/users/user/${auth.userId}`
				);
				setLoadedUser(userData.user);

				const accountData = await sendRequest(
					`${process.env.REACT_APP_BACKEND_URL}/accounts/${auth.accountId}`
				);

				setLoadedAccount(accountData.account);
			} catch (err) {
				console.warn('Failed to fetch user and account data: ', err);
			}
		};
		fetchData();
	}, []);

	useEffect(() => {
		const fetchGroups = async () => {
			if (auth.userIsAdmin === true) {
				try {
					const responseData = await sendRequest(
						`${process.env.REACT_APP_BACKEND_URL}/groups/account/${auth.accountId}`
					);

					setLoadedGroups(responseData.accountGroups);
					dispatch({
						type: ACTIONS.ADMIN_AVAILABLE_GROUPS,
						payload: { adminAvailableGroups: responseData.accountGroups },
					});
				} catch (err) {
					console.warn('Failed to initialize admin groups: ', err);
				}
			} else {
				try {
					const responseData = await sendRequest(
						`${process.env.REACT_APP_BACKEND_URL}/groups/user/${auth.userId}`
					);
					setLoadedGroups(responseData.userGroups);
					dispatch({
						type: ACTIONS.USER_AVAILABLE_GROUPS,
						payload: { userAvailableGroups: responseData.userGroups },
					});
				} catch (err) {
					console.warn('Failed to initialize users groups: ', err);
				}
			}
		};
		fetchGroups();
	}, [sendRequest, auth.userId, auth.accountId]);

	useEffect(() => {
		if (loadedAccount) {
			window.erxesSettings = {
				messenger: {
					brand_id: '6tLoyF',
					forms: [
						{
							brand_id: '6tLoyF',
							form_id: 'YCJtFY',
						},
					],
					email: loadedUser.userEmail,
					// phone: "88833331111",
					data: {
						// avatar:
						//   "https://cdn1.iconfinder.com/data/icons/female-avatars-vol-1/256/female-portrait-avatar-profile-woman-sexy-afro-2-512.png",
						firstName: loadedUser.userFirstName,
						lastName: loadedUser.userLastName,

						// birthDate: new Date("2020-01-01"),

						adminStatus: auth.isAdmin ? 'Yes' : 'No',
						// position: "position",
						// department: "department",

						// doNotDisturb: "Yes",
						code: auth.userId,

						// updatePlan: new Date("2020-04-25"),
						// plan: "paid",
						tag: 'customer',
					},
					companyData: {
						name: loadedAccount.accountName,
					},
				},
			};

			(() => {
				const script = document.createElement('script');
				script.src =
					'https://brisk.app.erxes.io/widgets/build/messengerWidget.bundle.js';
				script.async = true;

				const entry = document.getElementsByTagName('script')[0];
				entry.parentNode.insertBefore(script, entry);
			})();
		}
	}, [loadedAccount]);

	const changeButtonStyle = window.location.href.includes('history');

	return (
		<React.Fragment>
			<ErrorModal error={isError} onClear={clearError} />
			<div className='header_wrapper'>
				<Header title={pageTitle()} />
				<div className='container_nav_top'>
					{/* <Button
            // disabled={true}
            buttonSize="btn--default"
            buttonMargin="btn--14px--right"
          >
            <div className="icon_header_button">
              {" "}
              <FaChartBar />{" "}
            </div>{" "}
            Report
          </Button> */}
					<Button
						buttonSize='btn--default'
						buttonMargin='btn--14px--right'
						buttonStyle={changeButtonStyle === true ? 'btn--default--dark' : ''}
						to={`/${auth.accountId}/${groups.selectedGroup.id}/history`}
					>
						<div className='icon_header_button'>
							{' '}
							<FaHistory
								className='react-icons slight-padding'
								size='1.22em'
							/>{' '}
						</div>{' '}
						History
					</Button>

					{/* {isLoading && (
            <div>
              <LoadingSpinner dark="dark" />
            </div>
          )} */}

					{loadedGroups && (
						<Dropdown
							groups={loadedGroups}
							goTo={goTo}
							defaultValue={
								loadedGroups
									? loadedGroups[0].groupName
									: 'This is perplexing...'
							}
						>
							{isLoading && <LoadingSpinner dark='dark' />}
						</Dropdown>
					)}
				</div>
			</div>
		</React.Fragment>
	);
};

export default MainHeader;
