import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useHttpClient } from "../../shared/hooks/http-hook";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import { useAuth } from "../../shared/hooks/auth-hook";

const AuthCallback = () => {
  const { sendRequest } = useHttpClient();
  const { accountId, groupId } = useAuth();
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (accountId === undefined || groupId === undefined) return;
        const code = new URLSearchParams(window.location.search).get("code");
        await sendRequest(
          `https://api.fastfeedback.app/api/auth/urlGoogle/${groupId}/${encodeURIComponent(code)}`
        ).catch((e) => console.log(e));
        history.push(`/connect/${accountId}/${groupId}/google-my-business`);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [accountId, groupId, history, sendRequest]);

  return (
    <React.Fragment>
      <LoadingSpinner />
    </React.Fragment>
  );
};

export default AuthCallback;
