import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { FaUserAlt } from 'react-icons/fa';

import Emoji from './Emoji';
import { ACTIONS, initState } from '../../reducers/group-reducer';
import { GroupContext } from '../../context/group-context';

const ProfileButton = (props) => {
	const { accountId, userId, logout, userFirstName } = props;
	// const { disconnectAccount } = useContext(ConnectionContext);
	const { dispatch } = useContext(GroupContext);

	const [dropdownOpen, setDropdownOpen] = useState(false);
	const toggle = () => setDropdownOpen((prevState) => !prevState);

	const clearData = () => {
		logout();
		dispatch({
			type: ACTIONS.CLEAR_GROUPS,
			payload: {
				initialState: initState,
			},
		});

		// disconnectAccount();
	};

	return (
		<div
			data-delay={0}
			className='profile_button_nav dropdown w-dropdown'
			onClick={toggle}
		>
			<div className='dropdown-toggle profile w-dropdown-toggle'>
				<div className='user_first_name_placeholder'>
					{userFirstName ? userFirstName.charAt(0) : <FaUserAlt />}
				</div>
			</div>
			<nav
				className={classnames(
					'dropdown-list',
					'profile_button',
					'w-dropdown-list',
					{
						'w--open': dropdownOpen,
					}
				)}
			>
				<Link
					to={`/${accountId}/${userId}/my-account`}
					className='dropdown-list_toggle w-dropdown-link'
				>
					My Account{' '}
					<Emoji customClass='profile-button' label='briefcase' symbol='💼' />
				</Link>

				{/* <a
          onClick={() => {
            window.Erxes.showMessenger();
          }}
          className="dropdown-list_toggle w-dropdown-link"
        >
          Chat with Support{" "}
          <Emoji customClass="profile-button" label="chat" symbol="💬" />
        </a> */}

				<button
					onClick={() => {
						window.Erxes.showMessenger();
					}}
					className='dropdown-list_toggle w-dropdown-link'
				>
					Chat with Support{' '}
					<Emoji customClass='profile-button' label='chat' symbol='💬' />
				</button>
				<button
					onClick={clearData}
					className='dropdown-list_toggle w-dropdown-link'
				>
					Logout <Emoji customClass='profile-button' label='wave' symbol='👋' />
				</button>
			</nav>
		</div>
	);
};
export default ProfileButton;
