import React from "react";

import FeedbackItem from "./FeedbackItem";
import NoticeNoFeedback from "./NoticeNoFeedback";

const FeedbackList = (props) => {
  if (props.items.length === 0) {
    return <NoticeNoFeedback />;
  }
  //  console.log(props.date);

  // const timeStamp = Math.floor(new Date(props.date) / 1000);
  // console.log(timeStamp);

  // function timeSince(date) {
  //   var seconds = Math.floor((new Date() - date) / 1000);
  //   console.log(seconds);

  //   var interval = Math.floor(seconds / 31536000);

  //   if (interval > 1) {
  //     return interval + " years";
  //   }
  //   interval = Math.floor(seconds / 2592000);
  //   if (interval > 1) {
  //     return interval + " months";
  //   }
  //   interval = Math.floor(seconds / 86400);
  //   if (interval > 1) {
  //     return interval + " days";
  //   }
  //   interval = Math.floor(seconds / 3600);
  //   if (interval > 1) {
  //     return interval + " hours";
  //   }
  //   interval = Math.floor(seconds / 60);
  //   if (interval > 1) {
  //     return interval + " minutes";
  //   }
  //   return Math.floor(seconds) + " seconds";
  // }
  // var aDay = 24 * 60 * 60 * 1000;
  // // console.log(timeSince(new Date(Date.now()-aDay)));
  // // console.log(timeSince(new Date(Date.now()-aDay*2)));

  return (
    <>
      <div>
        {props.items.map((feedback) => {
          return (
            <FeedbackItem
              key={feedback._id}
              id={feedback._id}
              score={feedback.feedbackScore}
              customer={feedback.feedbackCustomer}
              body={feedback.feedbackBody}
              date={feedback.feedbackCreated}
              group={feedback.feedbackGroup}
              from={feedback.feedbackFrom}
              reminded={feedback.feedbackReminded}
            />
          );
        })}
      </div>
    </>
  );
};

export default FeedbackList;
