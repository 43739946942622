import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { AuthContext } from '../../context/auth-context';
import { GroupContext } from '../../context/group-context';
import Logo from '../../../assets/images/brisk_logo.svg';
import NavLinksBottom from './NavLinksBottom';
import NavlinksTop from './NavLinksTop';
import NavButtonsTop from './NavButtonsTop';

const MainNavigation = (props) => {
	const auth = useContext(AuthContext);
	const {
		groups: { selectedGroup },
	} = useContext(GroupContext);

	const { reporting } = selectedGroup.groupConnections;

	return (
		<>
			<div className='utility_menu_cont light'>
				<div className='top_section_navigation'>
					<NavButtonsTop {...auth} />

					<NavlinksTop {...auth} />
				</div>
				<div className='bottom_nav'>
					<NavLinksBottom {...auth} {...reporting} />

					<div className='subtle_dividing_line navbar_less_margin' />
					<div className='logo_container w-inline-block w--current'>
						<Link to={`/${auth.accountId}/${auth.groupId}/reviews`}>
							<img src={Logo} alt='brisk-logo' className='logo brisk' />
						</Link>
					</div>
				</div>
			</div>
		</>
	);
};

export default MainNavigation;
