import React, { useState } from "react";

import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import { useHttpClient } from "../../shared/hooks/http-hook";

import * as TimeAgo from "../../shared/util/TimeAgo";
import HistoryPreview from "../components/HistoryPreview";

const HistoryItem = (props) => {
  const { isError, clearError } = useHttpClient();

  const [detailsModal, setDetailsModal] = useState(false);

  const showDetailsModal = () => {
    setDetailsModal(true);
  };

  const hideDetailsModal = () => {
    setDetailsModal(false);
  };

  const messageSentDate = new Date(props.created).getTime();
  // console.log("Time: " + feedbackItemDate);

  const dt = TimeAgo.inWords(messageSentDate, new Date());

  return (
    <>
      <ErrorModal error={isError} onClear={clearError} />
      {/* <Modal
        show={showConfirmModal}
        onCancel={hideDeleteModalHandler}
        header="Are you sure you want to continue?"
        description={`You’re about to delete ${props.name}.‍ Are you sure you want to proceed?`}
        footer={
          <React.Fragment>
            <Button
              onClick={hideDeleteModalHandler}
              buttonMargin="btn--14px--right"
            >
              Cancel
            </Button>
            <Button
              onClick={confirmDeleteHandler}
              buttonStyle="btn--default--danger"
              buttonCustom="btn--wide"
            >
              Delete
            </Button>
          </React.Fragment>
        }
      ></Modal> */}
      <HistoryPreview
        show={detailsModal}
        onCancel={hideDetailsModal}
        header={`Message to ${props.name}`}
        name={props.name}
        message={props.body}
        channel={props.contact.includes("+44") ? "SMS" : "Email"}
        contact={props.contact}
        timeAgo={dt}
        goal={props.goal}
      />

      <div>
        <a
          href="#"
          onClick={showDetailsModal}
          className="link-block w-inline-block"
        >
          <div className="table-links">{props.name}</div>
        </a>
      </div>
      <div>{props.contact}</div>
      <div title={props.body}>
        {props.body.length > 30 ? props.body.slice(0, 30) + "..." : props.body}
      </div>
      <div className="coming-soon-field">{props.goal}</div>
      <div>Sent {dt}</div>
      <div id="w-node-e8d686272435-50ca207c" className="table-action-button">
        <a
          href="#"
          onClick={showDetailsModal}
          className="link-block w-inline-block"
        >
          <div className="table-links">View Details</div>
        </a>
      </div>
    </>
  );
};

export default HistoryItem;
