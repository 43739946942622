import React, { useContext } from "react";

import Button from "../../shared/components/FormElements/Button";
import { GroupContext } from "../../shared/context/group-context";
import { AuthContext } from "../../shared/context/auth-context";

const NoticeGoogle = (props) => {
  const {
    groups: { selectedGroup },
  } = useContext(GroupContext);
  const auth = useContext(AuthContext);
  return (
    <div className="container_state_notice">
      <div className="notification_tile google_page">
        <div className="notification_img_cont google" />
        <div className="notification_text_wrapper">
          <h3 className="notification_header">
            Don't miss out, connect your Google Business profile!
          </h3>
          <div className="notification_sub_text max_width">
            Connecting takes just a few steps and is no extra cost. You'll be
            able to receive and respond to Google messages, pull in reviews even
            faster and respond to reviews in getseen.
          </div>
        </div>
        <Button
          to={`/connect/${auth.accountId}/${selectedGroup.id}/google-my-business`}
          buttonCustom="btn--notice"
        >
          Connect
        </Button>
      </div>
    </div>
  );
};

export default NoticeGoogle;
