import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';

import TemplatesList from '../components/TemplatesList';
import ModalView from '../../shared/components/Navigation/ModalView';
import ErrorModal from '../../shared/components/UIElements/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import ModalHeader from '../../shared/components/Navigation/ModalHeader';
import NoticeTemplates from '../components/NoticeTemplates';
// import NoticeDummyTemplates from "../components/NoticeDummyTemplates";
import Button from '../../shared/components/FormElements/Button';

import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';

const Templates = () => {
	const accountId = useParams().accountId;
	const auth = useContext(AuthContext);
	const [loadedTemplates, setLoadedTemplates] = useState();
	const { isLoading, isError, sendRequest, clearError } = useHttpClient();

	useEffect(() => {
		const fetchTemplates = async () => {
			try {
				const responseData = await sendRequest(
					`${process.env.REACT_APP_BACKEND_URL}/templates/account/${accountId}?tag=`
				);

				setLoadedTemplates(responseData.accountTemplates);
			} catch (err) {}
		};
		fetchTemplates();
	}, [sendRequest, accountId]);

	// console.log(loadedTemplates && loadedTemplates.length);

	return (
		<ModalView goBack={`/${auth.accountId}/${auth.groupId}/settings`}>
			<ErrorModal error={isError} onClear={clearError} />
			<ModalHeader
				pageTitle='Templates'
				pageSubTitle='Ask for, & reply to, online reviews like never before...'
			>
				<Button to={`/${accountId}/template/create`}>Create Template</Button>
			</ModalHeader>

			{loadedTemplates && loadedTemplates.length <= 2 ? (
				<NoticeTemplates numberOfTemplates={loadedTemplates.length} />
			) : null}
			{isLoading && (
				<div className='center'>
					{' '}
					<LoadingSpinner dark='dark' />
				</div>
			)}

			{!isLoading && loadedTemplates && (
				<TemplatesList items={loadedTemplates} />
			)}
		</ModalView>
	);
};

export default Templates;
