import React, { useContext } from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";

import { GroupContext } from "../../shared/context/group-context";
import { AuthContext } from "../../shared/context/auth-context";
import Button from "../../shared/components/FormElements/Button";
import Backdrop from "../../shared/components/UIElements/Backdrop";

import "./MessagePreview.css";

const PreviewContent = (props) => {
  /**
   * TODO fixed error : Objects are not valid as a React child (found: [object Promise])
   * Functional components should not be async.
   */
  const {
    groups: { selectedGroup },
  } = useContext(GroupContext);

  const auth = useContext(AuthContext);

  const validateNumber = (input) => {
    const numberPattern = /[0-9]/;
    const namePattern = /^[a-zA-Z]/;
    const plusPattern = /[+]/;
    const iName = namePattern.exec(input);
    const iNumber = numberPattern.exec(input);
    if (iName !== null && iNumber !== null) {
      const iPlus = plusPattern.exec(input);
      const number =
        input.substr(iNumber.index).slice(0, 2) === "44"
          ? `+${input.substr(iNumber.index)}`
          : input.substr(iNumber.index).slice(0, 1) === "0"
            ? `${`+44${input.substr(iNumber.index).slice(1)}`}`
            : `${`+44${input.substr(iNumber.index)}`}`;
      const name = input
        .slice(iName.index, iPlus?.index ? iPlus?.index : iNumber.index)
        .replace(/,/g, "")
        .trim();
      return `${name}, ${number}`;
    }
  };

  const customerObj =
    (props.messageTo &&
      props.messageTo
        .trim()
        .split("\n")
        .map((input) => validateNumber(input))
        .join("\n")) ||
    "";

  const findFirstName = (fullName) => {
    const prefix = ["Mr", "Mrs", "Dr"];
    const pref = prefix.filter(pref => fullName.toLowerCase().includes(pref.toLowerCase()));
    if (pref.length > 0) {
      return `${pref[0]}. ${fullName.split(" ")[1]}`;
    } else {
      return fullName.split(" ").shift();
    }
  }

  const processedMessage =
    props.messageBody &&
    customerObj.split("\n").length > 0 &&
    props.messageBody
      .replace(
        "--CustomerFirstName--",
        props.messageTo
          ? findFirstName(customerObj.split(",").shift()) || ""
          : "--CustomerFirstName--"
      )
      // .replace(
      //   "--CustomerLastName--",
      //   props.messageTo
      //     ? customerObj[1].replace(",", "") || ""
      //     : "--CustomerLastName--"
      // )
      .replace("--GroupName--", selectedGroup.groupName)
      .replace("--ReviewLink--", "new.fdbck.co.uk/abc123")
      .replace("--MyFirstName--", auth.userFirstName)
      .replace("--ServiceLink--", "new.fdbck.co.uk/abc123");

  const content = (
    <div className="notice_container message-preview">
      <button
        className="close_icon absolute_notice w-inline-block"
        onClick={props.onCancel}
      />
      <header className={`notice_heading ${props.headerClass}`}>
        <h3>
          <strong>{props.header}</strong>
        </h3>
      </header>

      <div className="email-content-wrapper">
        <div className="row center preview">
          <div className="preview-buttons">
            <Button
              buttonMargin="btn--14px--right"
              buttonStyle="btn--default--dark"
            >
              <div>SMS</div>
            </Button>
            <Button disabled={true} buttonCustom="disabled">
              <div>Email</div>
            </Button>
          </div>
        </div>
        <div className="mobile-preview">
          <div className="mobile-inner">
            <div className="message-block top">
              <div className="text-block-2">
                Here&#x27;s what your message will look via text message 👇
              </div>
            </div>
            <div className="message-block">
              <div className="text-block-2">
                {processedMessage
                  ? processedMessage
                  : "🤔 Please type something first..."}
              </div>
            </div>
            <div className="phone-line"></div>
          </div>
        </div>
      </div>
    </div>
  );
  return ReactDOM.createPortal(content, document.getElementById("modal-hook"));
};

function MessagePreview(props) {
  return (
    <React.Fragment>
      {props.show && <Backdrop onClick={props.onCancel} />}
      <CSSTransition
        in={props.show}
        mountOnEnter
        unmountOnExit
        timeout={0}
        classNames="modal"
      >
        <PreviewContent {...props} />
      </CSSTransition>
    </React.Fragment>
  );
}

export default MessagePreview;
