import React from "react";
import { FaCheck } from "react-icons/fa";

import Button from "../../shared/components/FormElements/Button";

const NoticeUnresponded = () => {
  return (
    <div className="container_state_notice">
      <div className="notification_tile feedback">
        <div className={`notification_img_cont`}>
          <FaCheck />
        </div>
        <div className="notification_text_wrapper">
          <h3 className="notification_header_black">You're all caught up!</h3>
          <div className="notification_sub_text max_width">
            Check back soon for any new reviews to respond to here.
            {/* <a href="/guide-link" className="embedded_text_link">
              <span>Learn More</span>
            </a> */}
          </div>
        </div>
        <Button to="" buttonCustom="btn--notice">
          View Guide
        </Button>
      </div>
    </div>
  );
};

export default NoticeUnresponded;
