import React, { useContext } from "react";
import { FaHistory } from "react-icons/fa";

import { GroupContext } from "../../shared/context/group-context";

import Button from "../../shared/components/FormElements/Button";

const NoticeHistory = () => {
  const {
    groups: { selectedGroup },
  } = useContext(GroupContext);

  return (
    <div className="container_state_notice">
      <div className="notification_tile feedback">
        <div className={`notification_img_cont`}>
          <FaHistory />
        </div>
        <div className="notification_text_wrapper">
          <h3 className="notification_header_black">
            Messages sent from {selectedGroup.groupName}...
          </h3>
          <div className="notification_sub_text max_width">
            This is a list of all messages that you have sent from{" "}
            {selectedGroup.groupName}. Soon, you'll be able to track the status
            of each message request via email.
            <a href="/guide-link" className="embedded_text_link">
              {/* <span>Learn More</span> */}
            </a>
          </div>
        </div>
        <Button to="" buttonCustom="btn--notice">
          View Guide
        </Button>
      </div>
    </div>
  );
};

export default NoticeHistory;
