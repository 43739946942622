import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { FaFilter, FaSearch } from 'react-icons/fa';

import { GroupContext } from '../../shared/context/group-context';
import ReviewsList from '../components/ReviewsList';
import { useHttpClient } from '../../shared/hooks/http-hook';
import TableNavigation from '../../shared/components/Navigation/TableNavigation';
import NoticeImportingReviews from '../components/NoticeImportingReviews';
import { AuthContext } from '../../shared/context/auth-context';
import Button from '../../shared/components/FormElements/Button';
import DateDropDown from '../../shared/components/FormElements/DateDropDown';

const Reviews = ({ socket }) => {
	// console.log({ socket });
	const groupId = useParams().groupId;
	const [loadedReviews, setLoadedReviews] = useState([]);
	const [searchResults, setSearchResults] = useState(loadedReviews);
	const [documents, setDocuments] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const reviewsPerPage = 20;

	const [socketLoading, setSocketLoading] = useState(false);

	const [filter, setFilter] = useState('all');
	const [dateRange, setDateRange] = useState(0);
	const [searchTerm, setSearchTerm] = useState('');
	const [loadedTemplates, setLoadedTemplates] = useState([]);
	const auth = useContext(AuthContext);

	const {
		groups: { selectedGroup },
	} = useContext(GroupContext);

	const initCountState = {
		all: 0,
		unresponded: 0,
	};

	const [documentCount, setDocumentCount] = useState(initCountState);
	const { isLoading, sendRequest } = useHttpClient();

	socket.on('getReviews', ({ group, responseData }) => {
		if (group === selectedGroup.id) {
			setSocketLoading(false);
			setDocuments(responseData?.count ? responseData?.count : 0);
			setDocumentCount({
				all: responseData?.count || 0,
				unresponded: responseData?.unrespondedCount,
			});
			setLoadedReviews(responseData.results);
			setDocuments(responseData?.count ? responseData?.count : 0);
		}
	});

	socket.on('loaders', ({ group, message }) => {
		if (group === selectedGroup.id) {
			setSocketLoading(false);
			// console.log(message);
		}
	});

	useEffect(() => {
		setCurrentPage(1);
	}, [dateRange]);

	useEffect(() => {
		const fetchReviews = async () => {
			try {
				const responseData = await sendRequest(
					`${process.env.REACT_APP_BACKEND_URL}/reviews/group/${groupId}?page=${currentPage}&limit=${reviewsPerPage}&date=${dateRange}&filter=${filter}`
				);

				const accountTemplates = await sendRequest(
					`${process.env.REACT_APP_BACKEND_URL}/templates/account/${auth.accountId}?tag=Reply`
				);

				setLoadedTemplates(accountTemplates.accountTemplates);
				if (responseData.results) {
					setLoadedReviews(responseData.results);
					setDocuments(
						filter === 'all'
							? responseData.count
							: responseData.unrespondedCount
					);
					setSocketLoading(false);
					setDocumentCount({
						all: responseData?.count,
						unresponded: responseData?.unrespondedCount,
					});
				}
			} catch (err) {
				setSocketLoading(false);
				console.log('Not connected to GMB...', err);
				setDocuments(0);
				setLoadedReviews([]);
			}
		};
		fetchReviews();
	}, [sendRequest, groupId, currentPage, filter, dateRange]);

	const handleNextPage = () => {
		setCurrentPage((prev) => prev + 1);
		window.scrollTo({ top: 0 });
	};

	const handlePreviousPage = () => {
		setCurrentPage((prev) => prev - 1);
		window.scrollTo({ top: 0 });
	};

	const calcLastPage = Math.ceil(documents / reviewsPerPage);

	const queryUrlHandler = async (event) => {
		if (event !== undefined) {
			setCurrentPage(1);
			setFilter(event.target.id);
		}
	};

	const handleSearchOnChange = (e) => {
		// e.preventDefault();
		setSearchTerm(e.target.value.toLowerCase());
		setSearchResults(
			loadedReviews.filter((review) =>
				review.reviewerName.toLowerCase().includes(e.target.value.toLowerCase())
			)
		);
	};

	// console.log({ documentCount });

	return (
		<React.Fragment>
			{/* <ErrorModal error={isError} onClear={clearError} /> */}
			{/* <NoticeReviews /> */}
			{/* {!socketLoading && <NoticeImportingReviews />} */}

			<div className='filter_bubbles_wrapper'>
				<div className='left_side'>
					<Button
						id='all'
						onClick={queryUrlHandler}
						buttonSize='btn--default'
						buttonStyle={filter === 'all' && 'btn--default--dark'}
						buttonMargin='btn--14px--right'
					>
						{loadedReviews && `All (${documentCount.all || '0'})`}
					</Button>
					<Button
						id='unresponded'
						onClick={queryUrlHandler}
						buttonSize='btn--default'
						buttonStyle={filter === 'unresponded' && 'btn--default--dark'}
						buttonMargin='btn--14px--right'
					>
						<div className='icon_header_button'>
							<FaFilter size='1em' className='react-icons' />
						</div>
						{documentCount.unresponded || '0'} Unresponded
					</Button>

					<div className='container_nav_top'>
						<div className='search_input_cont margin-right'>
							{/* <form onSubmit={handleSearchOnChange}> */}
							<input
								id='messageCount'
								type='text'
								className='search_input'
								placeholder='Search by name...'
								onChange={handleSearchOnChange}
							/>

							<div type='submit' className='search_icon_cont'>
								<FaSearch />
							</div>
							{/* </form> */}
						</div>
						<DateDropDown setDateRange={setDateRange} dateRange={dateRange} />
					</div>
				</div>
			</div>

			{isLoading && (
				<>
					<NoticeImportingReviews />
				</>
			)}
			{!isLoading && loadedReviews && (
				<ReviewsList
					items={searchTerm ? searchResults : loadedReviews}
					documentCount={documentCount}
					setDocumentCount={setDocumentCount}
					filter={filter}
					templates={loadedTemplates}
					{...isLoading}
					// {...documentCount}
				/>
			)}

			{!isLoading && documentCount[filter] > 0 && (
				<TableNavigation
					onClickNext={handleNextPage}
					onClickPrevious={handlePreviousPage}
					firstPageNumber={String(currentPage)}
					lastPageNumber={String(calcLastPage)}
					disablePrev={currentPage === 1 ? true : false}
					disableNext={currentPage === calcLastPage ? true : false}
				/>
			)}
		</React.Fragment>
	);
};

export default Reviews;
