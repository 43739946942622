export const validateNumber = (input) => {
	const numberPattern = /[0-9]/;
	const namePattern = /^[a-zA-Z]/;
	const plusPattern = /[+]/;
	const iName = namePattern.exec(input);
	const iNumber = numberPattern.exec(input);
	if (iName !== null && iNumber !== null) {
		const iPlus = plusPattern.exec(input);
		const number =
			input.substr(iNumber.index).slice(0, 2) === '44'
				? `+${input.substr(iNumber.index)}`
				: input.substr(iNumber.index).slice(0, 1) === '0'
				? `${`+44${input.substr(iNumber.index).slice(1)}`}`
				: `${`+44${input.substr(iNumber.index)}`}`;
		const name = input
			.slice(iName.index, iPlus?.index ? iPlus?.index : iNumber.index)
			.replace(/,/g, '')
			.trim();
		return `${name}, ${number}`;
	}
};
