import React from "react";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";

function TableNavigation(props) {
  return (
    <div className="tile_wrapper">
      <div className="table-button-container">
        <div className="table-pagination">
          {props.firstPageNumber} of {props.lastPageNumber}
        </div>
        <button
          id="tablePrevious"
          className={`table-button left ${props.stylePrev}`}
          onClick={props.onClickPrevious}
          disabled={props.disablePrev}
        >
          <div className="present_text">
            <FaArrowLeft />
          </div>
        </button>
        <button
          id="tableNext"
          className={`table-button right ${props.styleNext}`}
          onClick={props.onClickNext}
          disabled={props.disableNext}
        >
          <div className="present_text">
            <FaArrowRight />
          </div>
        </button>
      </div>
    </div>
  );
}

export default TableNavigation;
