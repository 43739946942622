import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import { AuthContext } from '../../context/auth-context';

const MainFooter = (props) => {
	const auth = useContext(AuthContext);
	const [feedbackPopup, setFeedbackPopup] = useState(false);

	const handleFeedbackClick = () => {
		if (document.querySelector('#feedback')) {
			document.querySelector('#feedback').remove();
		}
		window.erxesSettings = {
			forms: [
				{
					brand_id: '6tLoyF',
					form_id: 'YCJtFY',
				},
			],
		};

		var script = document.createElement('script');
		script.src =
			'https://brisk.app.erxes.io/widgets/build/formWidget.bundle.js';
		script.id = 'feedback';
		script.async = true;
		var entry = document.getElementsByTagName('script')[0];
		entry.parentNode.insertBefore(script, entry);
	};

	return (
		auth.isLoggedIn && (
			<div className='container_footer'>
				<button
					type='button'
					onClick={() => {
						window.Erxes.showMessenger();
					}}
					className='footer_app_link'
				>
					Support
				</button>
				{/* <Link to='/compose' className='footer_app_link'>
					Compose
				</Link> */}
				<button
					type='button'
					data-erxes-modal='o3kXpy'
					onClick={handleFeedbackClick}
					className='footer_app_link'
				>
					Feedback
				</button>
			</div>
		)
	);
};

export default MainFooter;
