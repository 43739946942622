import React from "react";
import { withRouter } from "react-router";

import Button from "./Button";

const CancelButton = (props) => {
  console.log("Cancel CLicked");
  const handleClickClose = () => {
    props.history.goBack();
  };
  // TODO pass in custom routes as props
  return (
    <Button
      to={props.to}
      onClick={props.onClick || handleClickClose}
      buttonSize="btn--small"
      buttonMargin="btn--14px--right"
      className="button_default _14px_margin_right brisk_max_height w-button"
    >
      Cancel
    </Button>
  );
};

export default withRouter(CancelButton);
